.session-filterDropdown {
    position: relative;
    cursor: pointer;
}

.session-filterDropdown img {
    position: absolute;
    left: 15px;
    top: 9px;
    z-index: 10;
}

.session-table {
    margin-top: 30px;
}

.table-bordered {
    background-color: white !important;
}

.css-jl6rg4-singleValue {
    color: white !important;
    grid-area: 1/1/2/3;
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding-left: 30px;
    width: 111px;
    border: none;
    font-size: var(--smallfont);

    font-weight: 500;
}

.css-qc6sy-singleValue {
    color: white !important;
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    font-size: var(--smallfont);

    font-weight: 500;
}

.css-14el2xx-placeholder {
    color: white !important;
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    padding-left: 30px;
    font-size: var(--smallfont);
    font-weight: 500;

}

.css-mohuvp-dummyInput-DummyInput {
    background: 0;
    border: 0;
    caret-color: white !important;
    font-size: var(--smallfont);
    grid-area: 1/1/2/3;
    outline: 0;
    padding: 0;
    width: 1px;
    color: white !important;
    left: -100px;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(.01);
    -moz-transform: scale(.01);
    -ms-transform: scale(.01);
    transform: scale(.01);
}

.css-1arwg3o-placeholder {
    color: white !important;
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 51px;
    box-sizing: border-box;
    padding-left: 36px;
    font-size: var(--smallfont);

    font-weight: 500;
}

.css-qc6sy-singleValue {
    color: white !important;
    grid-area: 1/1/2/3;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding-left: 30px;
}

.session-filterDropdown .css-4titi-control {
    width: 111px;
    height: 36px;
}

.session-filterDropdown .css-ma9xf0-control {
    width: 111px;
    height: 36px;
}

/* table  */

.session-table table {
    width: 100%;
    margin-bottom: 0px !important;
}

.table>:not(caption)>*>* {
    padding: 0px;
    background-color: transparent;
    border-bottom-width: 0px;
    box-shadow: none;
}

.session-table tbody,
.session-table td,
.session-table tfoot,
.session-table th,
.session-table thead,
.session-table tr {
    border-color: none;
    border-style: none;
    border-width: 0;
    color: var(--white);
    background: transparent;
    font-weight: 400;
    font-size: 12px;
    line-height: var(--smallfont);
}

.session-table td {
    padding: 15px 20px;
}

.session-table tr td:last-child {
    text-align: center;
}

.session-table tr:first-child td {
    font-weight: 500;
    font-size: var(--smallfont);
    line-height: var(--linkfont);
}

.session-table tr:nth-last-of-type(odd) td {
    background: var(--input-bg);
}

.session-table tr:nth-last-of-type(odd) td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.session-table tr:nth-last-of-type(odd) td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.session-table .removedWarning {
    color: #FF0000;
}

.session-table .removedDisable {
    color: #868585;
}

.table-button {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.table-button span {
    font-weight: 600;
    font-size: var(--linkfont);
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.35px;
    color: var(--white);
    cursor: pointer;
}