.tower-title {
  position: relative;
  /* z-index: 99; */
}

.light .tower-heading {
  background: linear-gradient(270deg, #efeded 0%, #efeded 100%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.light .tower-heading h2 {
  color: #545252;
}

.light .tower-bet-box {
  background: #d9d9d9
}

.tower-heading {
  background: linear-gradient(270deg, #26282f 0%, #26282f 100%);
  border-radius: 2px;
  text-align: center;
  max-width: 260px;
  width: 100%;
  margin: 0px auto;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  z-index: 2;
}

.tower-heading h2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}

.tower-heading img {
  margin-right: 9px;
}

.cash-out {
  background: radial-gradient(100% 100% at 50% 0%,
      rgba(168, 245, 92, 0) 0%,
      rgba(168, 245, 92, 0.15) 100%),
    linear-gradient(270deg, #26282f 0%, #26282f 100%);
  border-radius: 2px;
  text-align: center;
  max-width: 260px;
  width: 100%;
  margin: 0px auto;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
}

.cash-out h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a8f55c;
  margin: 0;
}

.cash-out img {
  margin: 0px 9px;
}

.cash-out span {
  color: #fff;
}

.tower-lost {
  background: radial-gradient(100% 100% at 50% 0%,
      rgba(231, 38, 73, 0) 0%,
      rgba(231, 38, 73, 0.15) 100%),
    linear-gradient(270deg, #26282f 0%, #26282f 100%);
  border-radius: 2px;
  text-align: center;
  max-width: 260px;
  width: 100%;
  margin: 0px auto;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
}

.tower-lost h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #e72649;
  margin: 0;
}

.tower-lost img {
  margin: 0px 9px;
}

.tower-lost span {
  color: #fff;
  font-weight: bold;
}

.light .tower-main-box {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.tower-main-box {
  /* background: #1a1c22; */
  background: var(--main-bg);
  border-radius: 3px;
  padding: 42px 15px 60px 15px;
  max-width: 483px;
  width: 100%;
  position: relative;
  margin: 0px auto;
  /* display: grid;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
    grid-gap: 5px; */
}

.tower-list-row-two {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  grid-gap: 5px;
  margin-bottom: 5px;
}

.tower-list-row-three {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  grid-gap: 5px;
  margin-bottom: 5px;
}

.light .loser-tower-box,
.light .alt-winner-tower-box,
.light .winner-tower-box,
.light .bomb-tower-box {
  background-color: #00cccb !important;
}

.tower-list-row-four {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  grid-gap: 5px;
  margin-bottom: 5px;
}

.tower-list-row-hard {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minmax(0px, 1fr));
  grid-gap: 5px;
  margin-bottom: 5px;
}

.tower-box {
  background: #363943;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-align: center;
  padding: 11px 0px;
  width: 100%;
}

.light .tower-box h6 {
  color: #747474;
}

.tower-box h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin: 0;
}

.tower-box img {
  margin-right: 8px;
}

.tower-body .roll-dice .btn,
.tower-body .roll-dice .btn:hover,
.tower-body .roll-dice .btn:focus,
.tower-body .roll-dice .btn-primary:not(:disabled):not(.disabled):active {
  max-width: 300px;
  width: 100%;
}

.light .tower-body .roll-dice {
  background-color: #ffffff;
}

.tower-body .roll-dice {
  text-align: center;
  background: #1a1c22;
  border-radius: 0px 0px 4px 4px;
  max-width: 311px;
  width: 100%;
  padding: 6px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
}

.tower-bet-box {
  background: #1f2127;
  border-radius: 0px 0px 3px 3px;
  padding: 50px 18px 20px 18px;
  max-width: 483px;
  width: 100%;
  margin: 0px auto;
  position: relative;
  /* z-index: 99; */
}

.light .tower-bet-box label {
  color: #3a3b40;
}

.tower-bet-box label {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #8d8e92;
  margin-bottom: 5px;
  margin-top: 18px;
}

.tower-bet-box .bet-input .form-control,
.tower-bet-box .bet-input .form-control:focus {
  height: 41px;
}

.tower-bet-box .bet-input-btn {
  height: 41px;
}

.light .bet-input-btn .btn {
  color: #444242;
}

.level-btn {
  background: #23252b;
  border-radius: 4px;
  padding: 2px 0px;
  text-align: center;
}

.level-btn .btn,
.level-btn .btn:hover,
.level-btn .btn:focus,
.level-btn .btn-primary:not(:disabled):not(.disabled):active {
  background: #363943;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: none;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  max-width: 89px;
  width: 100%;
  height: 39px;
}

.level-btn .btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.tower-body .change-seed {
  position: unset;
  bottom: unset;
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.tower-body .change-seed .btn,
.tower-body .change-seed .btn:hover,
.tower-body .change-seed .btn:focus,
.tower-body .change-seed .btn-primary:not(:disabled):not(.disabled):active {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  padding: 11px 12px;
}

.tower-page {
  padding-top: 50px;
}

.tower-page .dice-tab-grid {
  margin-top: 39px;
}

.level-btn .active-level-btn {
  background: #363943 !important;
}

.level-btn button {
  background: transparent !important;
}

.tower-box {
  height: 46px;
}

.tower-box.winner-tower-box img {
  height: 18px;
  margin: 0px;
}

.tower-box.alt-winner-tower-box img {
  height: 18px;
  margin: 0px;
}

.tower-box.loser-tower-box img {
  height: 18px;
  margin: 0px;
}

.tower-box.bomb-tower-box img {
  height: 18px;
  margin: 0px;
}

.light .winner-tower-game .tower-box {
  background: #c9cedd;
}

.winner-tower-game .tower-box {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  background: #23252b;
  /* #c9cedd */
}

.winner-tower-game .current-tower-row .tower-box {
  background: #363943;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
}

.tower-box.winner-tower-box {
  background: radial-gradient(100% 100% at 50% 0%,
      rgba(129, 240, 31, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(129, 240, 31, 0.25), rgba(129, 240, 31, 0.25)),
    #363943 !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.tower-box.alt-winner-tower-box {
  background: #363943;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.tower-box.alt-winner-tower-box img {
  opacity: 30%;
}

.tower-box.loser-tower-box {
  background: radial-gradient(100% 100% at 50% 0%, rgba(231, 38, 73, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(231, 38, 73, 0.25), rgba(231, 38, 73, 0.25)),
    linear-gradient(0deg, #363943, #363943);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.tower-box.bomb-tower-box {
  background: #363943;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.tower-box.bomb-tower-box img {
  opacity: 30%;
}

.tower-list-row.current-tower-row:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: solid 6px #e72649;
  border-left: solid 6px transparent;
  border-right: solid 6px transparent;
  left: -15px;
  transform: rotate(90deg);
  top: 15px;
}

.tower-list-row.current-tower-row:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: solid 6px #e72649;
  border-left: solid 6px transparent;
  border-right: solid 6px transparent;
  right: -15px;
  transform: rotate(-90deg);
  top: 15px;
}

.tower-list-row.current-tower-row {
  position: relative;
}

.triangle-6 {
  width: 60px;
  height: 30px;
  border-top: solid 30px rgb(200, 30, 50);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
  position: absolute;
}

.triangle.triangle1.triangle-6 {
  left: 8px;
  transform: rotate(-15deg);
  top: -10px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #16181e;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}

.triangle.triangle2.triangle-6 {
  right: 40px;
  transform: rotate(35deg);
  top: -18px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #16181e;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}

.triangle.triangle3.triangle-6 {
  right: -11px;
  transform: rotate(-209deg);
  bottom: 0px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #1a1c22;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}

.bet-size-input::placeholder {
  color: rgba(184, 184, 184, 0.39) !important;
}