.wrapper {
  display: flex;
}

.content {
  min-height: calc(100vh - 269px);
  transition: all 0s;
  /* background-color: var(--primary-bg); */
}

.sidebar-content {
  max-width: 220px;
  width: 100%;
  box-shadow: 1px 0px 20px rgb(0 0 0 / 15%);
  background: var(--sidenav-color);
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  transition: all 0.5s;
}

.content-wrapper {
  width: calc(100% - 220px);
  margin-left: 220px;
  /* transition: all 0.5s; */
}

.sidebar-expand .sidebar-content {
  left: -220px;
}

.sidebar-expand .content-wrapper {
  width: calc(100% - 0px);
  margin-left: 0;
}


.sidebar-expand .mini-sidebar-btn {
  right: -112px;
}


/* .content>.home-page {
  margin-left: 80px;
  max-width: calc(100vh - 80px);
} */

/* ********************** Side bar ************************* */

.sidebar-logo {
  /* padding: 15px 0; */
  padding: 15px 0px 15px 15px;
  position: relative;
}

.sidebar-menu-list {
  padding: 20px 0 40px 0;
  height: calc(100vh - 269px);
  overflow-y: scroll;
}

.sidebar-menu-list a,
.sidebar-menu-list button {
  display: flex;
  gap: 10px;
  margin-left: 45px;
  padding: 20px 0;
  color: var(--navtext-color);
  font-size: var(--linkfont);
  font-weight: 500;
  line-height: 19px;
  text-decoration: none;
  transition: all 0.5s;
  position: relative;
}

.sidebar-menu-list button,
.sidebar-menu-list button:hover,
.sidebar-menu-list button:active,
.sidebar-menu-list .btn:focus-visible {
  background: none;
  border: none;
  box-shadow: none;
}

.sidebar-menu-list a:hover:before,
.sidebar-menu-list button:hover:before {
  content: "";
  position: absolute;
  top: 22px;
  left: -20px;
  width: 5px;
  height: var(--smallfont);
  background: var(--primary-color);
  border-radius: 4px;
}

.sidebar-menu-list a:hover,
.sidebar-menu-list button:hover {
  color: var(--primary-color);
}

.sidebar-menu-list a:hover svg path {
  stroke: var(--primary-color);
}

.light .sidebar-menu-list svg path {
  stroke: var(--svg-color);
}

/* .width: 70%; */
/* :not(.btn-check)+.btn:active {
  background: transparent !important;
} */

/* ************************* Social media *********************** */
.sidebar-text-content {
  padding: 12px 12px 12px 45px;
  text-align: start;
}

.sidebar-text-content h4 {
  font-weight: 500;
  font-size: var(--smallfont);
  line-height: 24px;
  color: var(--navtext-color);
  margin: 0;
  padding-bottom: 10px;
}

.soical-media-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.soical-icon {
  background: var(--icon-color);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 99px;
  transition: all 0.5s;
  width: 40px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.soical-icon:hover {
  background: var(--primary-color);
}

/* ******************* Dark mode *********************** */
.toggle-button {
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  width: 100px;
  height: 40px;
  background-color: var(--togglenav-color);
  border-radius: 50px;
  position: relative;
  transition: 0.4s;
  background-image: url(../asset/image/sidebar/dark-moon.svg);
  background-repeat: no-repeat;
  background-position: 70px 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.mini-sidebar-btn .btn-primary {
  background-color: var(--toggle-nav);
}

.toggle-button:before {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  top: 0;
  bottom: 0;
  background-color: var(--icon-color);
  transition: 0.4s;
  background: url(../asset/image/sidebar/icon-sun.svg) no-repeat center var(--icon-color);
  transform: translateX(-1px);
}

.toggle-button:checked {
  background-color: #06b7b7;
  background-image: url(../asset/image/sidebar/dark-sun.svg);
  background-repeat: no-repeat;
  background-position: 10px 10px;
}

.toggle-button:checked:before {
  transform: translatex(65px);
  background-color: var(--icon-color);
  background: url(../asset/image/sidebar/icon-moon.svg) no-repeat center var(--icon-color);
  transition: 0.4s;
}

.sidebar-menu-list button.nav-spin-btn.btn.btn-primary {
  background: none;
}

/* *********************** mini side bar ********************** */
.mini-sidebar-menu-list {
  display: grid;
  gap: 40px;
  padding: 40px 0;
  width: 80px;
}

.mini-sidebar-menu-list a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  transition: all 0.5s;
}

.mini-sidebar-menu-list a svg path {
  /* stroke: var(--primary-color); */
  stroke: var(--svg-color);

}

.mini-sidebar-menu-list a:hover svg path,
.sidebar-menu-list button:hover svg path {
  stroke: var(--primary-color);
}

.mini-sidebar-content {
  background: var(--sidenav-color);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.15);
  max-width: 80px;
  width: 100%;
  position: fixed;
  left: -80px;
  top: 0;
  bottom: 0;
  z-index: 999;
  transition: all 0.5s;
}

.sidebar-expand .mini-sidebar-content {
  left: 0px;
}

.mini-sidebar-logo {
  position: relative;
  text-align: center;
}

.mini-sidebar-btn {
  position: absolute;
  top: 20px;
  right: -15px;
  z-index: 999;
}

.mini-sidebar-btn img {
  transform: rotate(180deg);
  transition: all 0.2s;
}

.sidebar-expand .mini-sidebar-btn img {
  transform: rotate(0deg);
}

.mini-sidebar-btn button {
  width: var(--largefont);
  height: var(--largefont);
  background: var(--minitoggle-color);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0;
  border: none;
}

.mini-sidebar-btn button:hover,
.mini-sidebar-btn button.btn:first-child:active {
  /* background: #2b2b37; */
  background: var(--minitoggle-color);
}

@media screen and (max-width:767px) {
  .sidebar-content {
    max-width: 170px;
    width: 100%;
  }

  .sidebar-text-content {
    padding: 12px 12px 12px 20px;
  }

  .sidebar-menu-list {
    margin-left: -22px;
  }

  .sidebar-logo {
    padding: 15px 0px 15px 0px;
  }

  .soical-icon {
    width: 30px;
    height: 30px;
  }

}