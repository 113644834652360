/* **************************** icon-btn ************************** */

.skill-dark-button button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 10px;
  width: 135px;
  height: 40px;
  background: var(--primary-color);
  border-radius: 30px;
  border: var(--primary-color);
}

.skill-dark-button button:hover {
  background: var(--primary-color-hover);
  border: var(--primary-color-hover);
}

.skill-dark-button button.btn:first-child:active {
  background: var(--primary-color-hover);
  border: var(--primary-color-hover);
}

.LinkBtn {
  text-decoration: none;
}

.skill-dark-button h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px;
}

.skill-dark-button h5 {
  color:black;
}