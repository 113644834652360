/* ******************** transparent-btn ************************ */

.skill-transparent-button button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  gap: 8px;
  width: 140px;
  height: 40px;
  border-radius: 30px;
  background: transparent;
  color: var(--primary-color);
  border: none;
}

.skill-header-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.skill-transparent-button button:hover {
  background: transparent;
  border: none;
  color: var(--primary-color);
}

.skill-transparent-button button.btn:first-child:active {
  background: transparent;
  border: none;
  color: var(--primary-color);
}