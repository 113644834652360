.react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
    background: var(--input-color);
    border-radius: 4px;
    height: 42px;
    border:none !important;
    padding: 0px 8px;
}

.reg-form span {
    color: #8b7c7c;
    display: inline;
}

.react-date-picker {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.react-date-picker__inputGroup__input {
   color:var( --placeholder-color) !important;  
}

.react-date-picker__calendar-button {
    display: none !important;
}

.react-date-picker__inputGroup>input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.react-date-picker__clear-button , react-date-picker__button{
   display: none;
}
.react-date-picker__button svg {
    display: none !important;
}

.react-date-picker__calendar .react-calendar {
    background: var(--input-color);
    color: #0dcaf0;
}

.react-calendar__tile--active {
    background: #595f64;
    /* color: */
}

.react-calendar__month-view__days__day--weekend {
    color: #6c757d;
}

.react-calendar__navigation button {
   /* color:#0dcaf0; */
   color: #9E9E9E;
}

.react-date-picker * {
    color: #9E9E9E;
}

/* .react-calendar__tile--now {
    background: #00BCD4;
} */
