.chat-container.offcanvas {
  background: var(--primary-bg);
  border-radius: 10px 0px 0px 10px;
  max-width: 343px;
}

.chat-container .offcanvas-header {
  padding: 20px;
}

.chat-container .offcanvas-body {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.chat-container .chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chat-container .chat-status {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--linkfont);
}

.chat-status::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  background-color: #fcb122;
  left: -15px;
  top: 7px;
  filter: drop-shadow(0px 0px var(--linkfont) #fcb122);
}

.chat-container .chat-header h6 {
  font-weight: 400;
  font-size: 22px;
  line-height: var(--mediumfont);
  /* identical to box height */
  letter-spacing: 0.3px;
  color: var(--white);
  margin: 0;
}

.chat-container .chat-status span {
  font-weight: 400;
  font-size: var(--smallfont);
  line-height: var(--linkfont);
  letter-spacing: 0.2px;
  color: #fcb122;
  filter: drop-shadow(0px 0px var(--linkfont) #fcb122);
}

/* .arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 1px;
  width: 24px;
  height: 24px;
  background: var(--input-color);
  border-radius: 4px;
  cursor: pointer;
} */

.chat-container .chat-header h6 {
  font-weight: 400;
  font-size: 22px;
  line-height: var(--mediumfont);
  /* identical to box height */
  letter-spacing: 0.3px;
  color: var(--white);
  margin: 0;
}

.chat-container .chat-status span {
  font-weight: 400;
  font-size: var(--smallfont);
  line-height: var(--linkfont);
  letter-spacing: 0.2px;
  color: #fcb122;
}

.arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 1px;
  width: 24px;
  height: 24px;

  /* dark 300 */
  background: var(--input-color);
  border-radius: 4px;
}

.message-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  min-height: calc(100vh - 178px);
  overflow: auto;
  scroll-behavior: smooth;
}

.message-container::-webkit-scrollbar {
  width: 0px;
}

.message-container .message-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-container .message-body {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.message-container .chat-user-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-container .user-chat-box {
  padding: 8px;
  max-width: 243px;
  width: 100%;
  background: var(--input-color);
  border-radius: 10px;
}

.message-container .user-chat-box span {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--white);
}

.message-container .chat-user-name h6 {
  font-weight: 500;
  font-size: var(--smallfont);
  line-height: var(--linkfont);
  display: flex;
  align-items: center;
  color: #f5f5f5;
  margin: 0;
}

.message-container .chat-user-name span.chat-time {
  font-weight: 400;
  font-size: 12px;
  line-height: var(--smallfont);
  color: #f5f5f5;
}

.send-chat-wrapper {
  position: relative;
}

.send-chat-wrapper input {
  padding: 12px 10px;
  width: 100%;
  height: 52px;

  /* dark 300 */
  background: var(--input-color);
  /* Primary */
  border-radius: 4px;
  color: #fff;
  font-weight: 300;
  font-size: var(--smallfont);
  line-height: var(--smallfont);
  border: none;
  padding-right: 80px;
  margin-top: 5px;
}

.send-chat-wrapper input:focus {
  background: var(--input-bg);
  border: none;
  box-shadow: none;
  color: #fff;
}

.send-message-btn {
  display: flex;
  align-items: center;
  column-gap: 12px;
  position: absolute;
  top: 12px;
  right: 10px;
}

.send-chat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 28px;
  height: 28px;

  /* Primary */
  background: var(--fontcolor);
  box-shadow: 0px 0px 5px rgba(0, 204, 203, 0.5);
  border-radius: 4px;
  cursor: pointer;
}

.send-message-btn span {
  cursor: pointer;
}

.send-message-btn span:hover svg path {
  stroke: var(--fontcolor);
}

.message-wrapper img {
  width: 46px;
  height: 46px;
  border-radius: 10px;
}