.skill-color-button button {
  padding: 5px 20px;
  background: var(--fontcolor);
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: var(--smallfont);
  line-height: 29px;
  color:black;
}
.skill-color-button button:hover {
  background: var(--primary-color-hover);
  color: var(--white);
}
.skill-color-button button.btn:first-child:active {
  background: var(--fontcolor);
  color: var(--white);
}


