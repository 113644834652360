.mines-game {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 65px;
}

.mines-tab {
  max-width: 342px;
  width: 100%;
  background-color: #1c1c26;
  border-radius: 3px 0px 0px 3px;
  padding: 25px 23px;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.mines {
  padding-top: 10px;
}

.mines-tab .nav-tabs {
  border: none;
}

.mines-tab .nav-tabs .nav-link {
  width: 50%;
  border-color: transparent;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #8d8e92;
  max-height: 39px;
  padding: 11px 0px;
}

.mines-tab .nav-tabs .nav-item.show .nav-link,
.mines-tab .nav-tabs .nav-link.active {
  background: #363943;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: none;
  font-weight: 500;
  color: #ffffff;
}

.mines-bet-box {
  margin: 16px 0px 5px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mines-bet-box .form-group {
  width: calc(50% - 4px);
  margin-bottom: 14px;
}

.mines-bet-box .form-group:first-child {
  width: 100%;
}

.mines-bet-box .form-control,
.mines-bet-box .form-control:focus {
  height: 41px;
  text-align: center;
  color: #ffffff;
  box-shadow: none;
  background: rgba(38, 40, 47, 0.5);
  border: 1px solid #363943;
  box-sizing: border-box;
  border-radius: 4px;
}

.mines-bet-box .form-control::placeholder {
  color: #ffffff;
}

.manual-tab form label {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #8d8e92;
  margin: 0;
  display: block;
  padding-bottom: 4px;
}

.manual-tab .roll-dice .btn,
.manual-tab .roll-dice .btn:hover,
.manual-tab .roll-dice .btn:focus,
.manual-tab .roll-dice .btn-primary:not(:disabled):not(.disabled):active {
  max-width: none;
}

.change-seed .btn,
.change-seed .btn:hover,
.change-seed .btn:focus,
.change-seed .btn-primary:not(:disabled):not(.disabled):active {
  background: #23252b;
  border-radius: 3px;
  box-shadow: none;
  border: none;
  padding: 11px 16px;
  color: #8d8e92;
}

.change-seed {
  position: absolute;
  bottom: 20px;
}

.change-seed .btn::before {
  content: url("../../../assets/images/mines/seed.svg");
  margin-right: 8px;
}

.mines-game-sec {
  display: grid;
  grid-template-columns: repeat(5, minmax(0px, 1fr));
  grid-gap: 11px;
  gap: 11px;
  /* max-width: calc(100% - 342px); */
  padding: 21px 35px;
  width: 100%;
  background: #13131b;
  border-radius: 3px;
}

.mines-game-tiles {
  height: 119px;
  background: #363943;
  border-radius: 3px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mines-game .active-game-tile {
  background: radial-gradient(100% 100% at 50% 0%,
      rgba(169, 245, 92, 0.5) 0%,
      rgba(168, 245, 92, 0) 100%),
    #474951;
}

.mines-game .active-game-tile p {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #a8f55c;
  max-width: 92px;
  height: 29px;
  background: rgba(168, 245, 92, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 2px;
  margin: 0px auto;
  margin-bottom: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: -10px;
}

.mines-game .mines-game-tiles.active-game-tile img {
  height: 55px;
  width: 55px;
}

.mines-game .bet-input-btn {
  height: 41px;
}

.mines-game .nav-tabs {
  background: #23252b;
  border-radius: 4px;
}

/***** automated tab section ********/
.automated-tab .form-group {
  width: 100% !important;
}

.automated-tab .automated-bet-half {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.automated-tab .automated-bet-half .form-group {
  width: 49% !important;
}

.on-win-section .bet-input p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #8d8e92;
  margin: 0px !important;
  background: #363943;
  border-radius: 0;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.on-win-section {
  width: 100%;
}

.on-win-section input {
  width: 45%;
}

.on-win-section .bet-input span {
  position: absolute;
  right: 10px;
  top: 9px;
}

.on-win-section .left-win-section {
  display: flex;
  width: 55%;
  background: rgba(54, 57, 67, 0.5);
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 3px 0px 0px 3px;
  overflow: hidden;
}

.on-win-section .bet-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mines-game-tiles.win-game-tile-red {
  background: radial-gradient(100% 100% at 50% 0%,
      rgba(231, 38, 73, 0.5) 0%,
      rgba(231, 38, 73, 0) 100%),
    #474951;
  border-radius: 3px;
}

.mines-game-tiles.win-game-tile-green {
  background: radial-gradient(100% 100% at 50% 0%,
      rgba(169, 245, 92, 0.5) 0%,
      rgba(168, 245, 92, 0) 100%),
    #474951;
  border-radius: 3px;
  opacity: 0.5;
}

.mines-winner-loss {
  max-width: 204px;
  width: 100%;
  height: 92px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  padding: 23px;
  text-align: center;
  background: #363943;
  border-radius: 8px;
}

.mines-winner-box::before {
  content: "";
  position: absolute;
  background: rgba(231, 38, 73, 0.15);
  filter: blur(90px);
  width: 113px;
  height: 113px;
  border-radius: 50%;
}

.mines-right-section {
  max-width: calc(100% - 342px);
  position: relative;
  width: 100%;
}

.mines-winner-box h6 {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #a8f55c;
}

.mines-winner-box p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.mines-game-sec.winner-game {
  opacity: 0.25;
}

.mines-loss-box h6 {
  color: #e72649;
}

.mines-loss-box:before {
  position: absolute;
  width: 113px;
  height: 113px;
  background: rgba(231, 38, 73, 0.185);
  filter: blur(45px);
  content: "";
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.mines-winner-box::before {
  background: rgba(168, 245, 92, 0.15);
  filter: blur(45px);
  content: "";
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.bet-input-max-width {
  width: 100% !important;
}

.form-control:disabled {
  background-color: rgba(38, 40, 47, 0.5) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #8d8e92 !important;
}

.stop-auto-bet-btn {
  background-color: #e04545 !important;
  border-color: transparent !important;
}

@media screen and (max-width: 991px) {
  .mines-tab .roll-dice {
    position: absolute;
    top: 135px;
    width: 100%;
    margin: 0px auto !important;
    justify-content: center;
  }

  .mines-tab .change-seed {
    bottom: -120px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .mines-right-section {
    max-width: calc(100% - 0px);
    width: 100%;
  }

  .mines-tab {
    max-width: 600px;
  }

  .mines-tab .roll-dice button {
    max-width: 550px !important;
    width: 100% !important;
    height: 50px !important;
  }

  .mines-bet-box-inp {
    width: 100%;
  }
}