.ludoHeaderModal form {
    max-width: 1000px;
    width: 100%;
}

.ludoGameTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
}

.ludoGameTitle h2 {
    font-family: 'Work Sans';
    font-style: normal;
    margin: 0px;
    font-size: 32px;
    color: var(--white);
}

.gamePageBtn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.ludoHeaderModal .css-b4r0no-Input,
.ludoHeaderModal .css-14el2xx-placeholder {
    padding-left: 0px;
}

.ludoHeaderModal .css-14el2xx-placeholder {
    color: #eeeeee7a !important;
}

.gamePageBtn button.cancelBtn.btn.btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: var(--input-bg) !important;
    border: none !important;
    color: var(--secondry-color) !important;
    max-width: 170px;
    min-width: 143px;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    padding: 15px 33px;
}

.gamePageBtn button.createBtn.btn.btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding: 15px 33px;
    gap: 10px;
    max-width: 170px;
    min-width: 143px;
    width: 100%;
    height: 40px;
    background: linear-gradient(215.13deg, #04EEF9 0.32%, #00ADB5 99.95%), #00ADB5;
    border-radius: 25px;
    border: none;
}

.css-13yc3fr-control {
    background-color: var(--input-color) !important;
}

.css-hlu0h4-singleValue {
    color: var(--white) !important;
}