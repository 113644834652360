.settingPage {
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.setting-icon {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 6px;
}

.settingMenu-content {
    padding: 30px;
    width: calc(100% - 230px);
    background: var(--primary-bg);
    border-radius: 8px;
}


.setting-icon h4 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: var(--white);
    margin-bottom: 0px;
}

.setting-content {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.setting-menu {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    width: 200px;
    background: var(--primary-bg);
    border-radius: 8px;
    /* height: 362px; */
}

.settingmenuImg {
    position: relative;
    width: 100px;
    height: 100px;
}

.setting-menu .profileImg {
    width: 100px;
    height: 100px;
}

.settingmenuImg .editicon {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 75px;
    top: 75px;
}

.setting-menu .settingMenu-profilename {
    margin-top: 10px;
    font-weight: 400;
    font-size: var(--smallfont);
    line-height: var(--linkfont);
    color: var(--white);
}

.settingMenu-tab {
    margin-top: 20px;
    width: 100%;
}

.settingMenu-tab ul {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    margin-bottom: 0px;
}

.settingMenu-list li {
    padding: 10px 13px;
    list-style: none;
    font-weight: 500;
    font-size: var(--linkfont);
    line-height: 19px;
    letter-spacing: -0.01em;
    color: var(--light-background);
    cursor: pointer;
}

.settingPage a {
    text-decoration: none;
    cursor: pointer;
    border-left: 3px solid transparent;
}

.settingPage a.active {
    background-color: var(--input-bg);
    cursor: pointer;
    border-left: 3px solid var(--primary-color);
    transition: all 0.4s ease-in-out 0s;
}


@media screen and (max-width:991px) {
    .setting-content {
        flex-direction: column;
        gap: 30px;
    }

    .settingMenu-content {
        padding: 30px;
        width: 100%;
        background: var(--primary-bg);
        border-radius: 8px;
    }
}