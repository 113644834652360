/* *************************** How it work ******************************** */
.work-content-container {
    padding: 40px 0;
  }
  
  .work-content-container h2 {
    font-weight: 700;
    font-size: var(--largefont);
    line-height: 140%;
    color: var(--fontcolor);
    margin: 0;
    padding-bottom: 10px;
    text-align: center;
  }
  
  .work-content-container h2 span {
    color: var(--white);
  }
  
  .work-content-container p {
    font-weight: 400;
    font-size: var(--paragraphfont);
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.015em;
    color: var(--light-background);
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  
  .work-content-container {
    padding: 40px 0;
  }
  
  .work-content-container h2 {
    font-weight: 700;
    font-size: var(--largefont);
    line-height: 140%;
    /* identical to box height, or 45px */
  
    /* Primary */
    color: var(--fontcolor);
    margin: 0;
    padding-bottom: 10px;
    text-align: center;
  }
  
  .work-content-container h2 span {
    color: var(--white);
  }
  
  .work-content-container p {
    font-weight: 400;
    font-size: var(--paragraphfont);
    line-height: 30px;
    /* or 167% */
    text-align: center;
    letter-spacing: -0.015em;
  
    /* grey-500 */
    color: var(--light-background);
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  
  .work-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
  }
  
  .work-content-left {
    max-width: 400px;
    width: 100%;
    display: grid;
    gap: 20px;
  }
  
  .work-content-box {
    display: flex;
    gap: var(--linkfont);
    padding: 20px;
    transition: all 0.5s;
    cursor: pointer;
  }

  .light .work-content-box {
    margin-left: 5px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  
  .work-content-box:hover {
    background: var(--primary-bg);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .work-create-icon {
    padding: 8px;
    width: 40px;
    height: 40px;
    background: rgba(249, 113, 121, 0.1);
    border-radius: 4px;
  }
  
  .work-content-right {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 843px;
    width: 100%;
  }
  
  .work-content-right img {
    max-width: 442px;
    width: 100%;
  }
  
  .work-earn-icon {
    padding: 8px;
    width: 40px;
    height: 40px;
    background: rgba(56, 187, 148, 0.1);
    border-radius: 4px;
  }
  
  .work-play-icon {
    padding: 8px;
    gap: 10px;
    width: 40px;
    height: 40px;
    background: rgba(253, 201, 101, 0.2);
    border-radius: 4px;
  }
  
  .work-share-icon {
    padding: 8px;
    width: 40px;
    height: 40px;
    background: rgba(112, 139, 235, 0.1);
    border-radius: 4px;
  }
  
  .work-box-text h6 {
    font-weight: 500;
    font-size: var(--paragraphfont);
    line-height: 21px;
    letter-spacing: -0.015em;
    color: var(--white);
    margin: 0;
    padding-bottom: 5px;
  }
  
  .work-box-text p {
    font-weight: 400;
    font-size: var(--smallfont);
    line-height: 140%;
    letter-spacing: -0.015em;
    color: var(--light-background);
    opacity: 0.7;
    text-align: start;
  }
  
  .work-content-right {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 843px;
    width: 100%;
  }