.footer-content {
  background: var(--sidenav-color);
  padding: 64px 0;
  /* height: calc(100vh - 100px); */
}

/* .content {
  min-height: calc(100vh - 269px);
} */

.footer-logo {
  text-align: start;
}

.footer-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-copy-right span {
  color: var(--navtext-color);
  font-weight: 400;
  font-size: var(--smallfont);
  line-height: 150%;
}

.footer-detail-right ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--largefont);
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-detail-right ul li a {
  font-weight: 400;
  font-size: var(--smallfont);
  line-height: 150%;
  color: var(--navtext-color);
  text-decoration: none;
  transition: all 0.5s;
}

.footer-detail-right ul li a:hover {
  color: var(--primary-color-hover);
}

.footer-content-container {
  display: grid;
  gap: var(--largefont);
}