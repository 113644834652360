.security-page {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.security-page .securityPage-tile {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.security-page h4 {
    font-weight: 500;
    font-size: var(--paragraphfont);
    line-height: 21px;
    letter-spacing: -0.015em;
    color: var(--white);
    margin-bottom: 0px;
}

.security-page p {
    font-weight: 400;
    font-size: var(--smallfont);
    line-height: 140%;
    color: #E2E8FF;
    margin-bottom: 0px;
}

.security-page .securityPage-tile p {
    color: var(--light-background);
}

.security-page .security-codecopy {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.securitycodeImg {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: var(--paragraphfont);
    max-width: 440px;
    width: 100%;
    height: 41px;
    background: var(--input-bg);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

}

.securitycodeImg img {
    cursor: pointer;
}

.security-page .security-codecopy span {
    font-weight: 400;
    font-size: var(--linkfont);
    line-height: 19px;
}

.security-page .security-qr {
    display: flex;
    flex-direction: column;
    gap: var(--smallfont);
}

.security-page .security-qr img {
    width: 100px;
    height: 113.02px;
    border-radius: 6px;
}

.security-twoFactorCode {
    position: relative;
}

.security-twoFactorCode img {
    position: absolute;
    top: 45px;
    left: 13.33px;
}

.security-twoFactorCode .form-control {
    padding: 12px;
    padding-left: 36px;
    max-width: 5var(--largefont);
    width: 100%;
    height: 44px;
    background: var(--input-bg);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: #fff;
}

.security-pageBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    max-width: 200px;
    width: 100%;
    height: 34px;
    background: var(--primary-color);
    border-radius: 30px;
    font-weight: 400;
    font-size: var(--smallfont);
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.35px;
    color: var(--white);
    border: none;
}

button.security-pageBtn.btn.btn-primary:hover,
button.security-pageBtn.btn.btn-primary:active,
button.security-pageBtn.btn.btn-primary:focus {
    background: var(--primary-color);
    color: var(--white);
}

@media screen and (max-width:768px) {
    .security-page .security-codecopy span {
        overflow-x: scroll;
    }
}