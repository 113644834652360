.generalSet-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.generalSet-container .basicDetails {
    width: calc(50% - 30px)
}

.generalSet-container .basicDetails form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
}

.generalSet-container span.react-date-picker__inputGroup__divider {
    color: var(--light-background);
}

.generalSet-container span {
    color: #D24E4E;
}

.generalSet-container .basicDetails .form-label {
    font-weight: 400;
    font-size: 12px;
    line-height: var(--smallfont);
}

.generalSet-container .basicDetails .form-control {
    padding: 12px;
    height: 44px;
    background: var(--input-bg);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: var(--light-background);
    font-weight: 400;
    font-size: var(--linkfont);
    width: 100%;
}

.generalSet-container .basicDetails .form-control::placeholder {
    font-weight: 400;
    font-size: var(--linkfont);
    line-height: 19px;
}

.generalSet-container .basicDetails-name {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.generalSet-name {
    width: 100%;
}

.generalSet-btn {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 76px;
    height: 34px;
    background: var(--primary-color);
    border-radius: 30px;
    color: var(--white);
}

button.generalSet-btn.btn.btn-primary:hover,
button.generalSet-btn.btn.btn-primary:active,
button.generalSet-btn.btn.btn-primary:focus {
    background: var(--primary-color);
    color: var(--white);
}

.generalSet-mail {
    position: relative;
}

.generalSet-mail #fiormBasicEmal {
    padding-left: 36px;
}

.generalSet-mail img {
    position: absolute;
    left: 13.33px;
    top: 43px;
}

.generalSet-DOB {
    display: flex;
    flex-direction: column;
    color: var(--light-background);
}

.generalSet-DOB .react-date-picker__button:enabled {
    cursor: pointer;
    display: none;
}

.generalSet-phone .form-control {
    padding-left: 38px !important;
}

.basicDetails .react-tel-input .selected-flag {
    background: var(--input-bg);

}

.basicDetails .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background: var(--input-bg);
}

.basicDetails .react-tel-input .flag-dropdown {
    border: 1px solid var(--input-bg);
    border-radius: 5px;
}

.basicDetails .react-tel-input .flag-dropdown.open .selected-flag {
    background: var(--input-bg);
    border-radius: 3px 0 0 0;
}

.basicDetails .react-tel-input .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
    background-color: var(--input-bg);
    width: 300px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
}

.basicDetails .react-tel-input .country-list::-webkit-scrollbar,
.basicDetails .modalTextArea::-webkit-scrollbar {
    background-color: var(--primary-bg);
    width: 5px;
}

.basicDetails .react-tel-input .country-list::-webkit-scrollbar-thumb,
.basicDetails .modalTextArea::-webkit-scrollbar {
    background-color: var(--input-bg);
    width: 5px;
}

.basicDetails .react-tel-input .country-list .country:hover {
    background-color: var(--primary-bg);
}

.basicDetails .react-tel-input .country-list .country.highlight {
    background: var(--primary-bg);
}

.modalContactUs.react-tel-input .in {
    background-position: -208px -55px;
    transform: scale(1.3);
}

/* reset password */
.resetChange {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: calc(50% - 30px);
}

.resetChange form {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.resetChange .form-label {
    font-weight: 400;
    font-size: 12px;
    line-height: var(--smallfont);
    color: var(--light-background);
}

.resetChange .reg-passwd {
    margin-top: 0px;
}

.resetChange .form-control {
    width: 100%;
    height: 44px;
    background: var(--input-bg);
    padding-left: 36px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: var(--white);
}

.resetChange form p {
    font-weight: 500;
    font-size: var(--paragraphfont);
    line-height: 21px;
    color: var(--white);
    margin-bottom: 0px;
}

@media screen and (max-width:768px) {
    .generalSet-container {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    .generalSet-container .basicDetails {
        width: 100%;
    }

    .resetChange {
        width: 100%;
    }
}