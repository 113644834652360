/* ******************************* FAQ ************************* */
.skill-faq-content {
  padding: 0px 20px;
}

.skill-faq-content-container {
  padding: 40px 0;
}

.skill-faq-content .accordion {
  background: transparent !important;
}

.skill-faq-content .accordion-item {
  background: transparent !important;
  border: none;
}

.skill-faq-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 60px;
}

.skill-faq-content .accordion-button {
  background: transparent !important;
  padding: 30px 0;
  box-shadow: none !important;
}

.skill-faq-content .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.skill-faq-head span {
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0.25px;
  color: var(--fontcolor);
}

.skill-faq-content-container h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: var(--fontcolor);
  margin: 0;
  text-align: center;
}

.skill-faq-content-container .skill-faq-head h3 {
  font-weight: 600;
  font-size: var(--mediumfont);
  line-height: 120%;
  color: var(--white);
  margin: 0;
  padding-left: 52px;
  text-align: start;
}



.skill-faq-content .accordion-button::after {
  background: url(../../asset/image/faq/accordion-icon.svg) no-repeat center !important;
  width: 48px;
  height: 48px;
  transform: rotate(90deg);
  transition: all 0.5s;
}

.skill-faq-content .accordion-button:not(.collapsed)::after {
  transform: rotate(225deg);
}

.skill-faq-content .accordion-item {
  color: var(--white);
  border-bottom: 1px solid rgb(205 214 218 / 7%);
  background: transparent !important;
}

.skill-faq-content .accordion-item:last-child {
  border-bottom: none;
}

.skill-faq-content .accordion-body {
  padding: 24px 20px 30px 91px;
}

.skill-faq-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var( --privacytext-color);
  margin: 0;
  max-width: 1000px;
  width: 100%;
}

.skill-faq-content .accordion-item:last-of-type .accordion-button.collapsed,
.skill-faq-content .accordion-item:last-of-type {
  border-radius: 0;
}