.skill-search {
  max-width: 500px;
  width: 100%;
  position: relative;
}

.skill-search input {
  max-width: 500px;
  width: 100%;
  height: 40px;
  background: var(--main-bg);
  border: 2px solid var(--main-bg);
  border-radius: 30px;
  padding-left: 22px;
  padding-right: 50px;
  margin-left: 50px;
  padding-bottom: 5px;
  color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.skill-search input::placeholder {
  color: var(--white);
}

.skill-search .form-control::placeholder {
  color: var(--secondry-color);
}

.skill-search .form-control:focus {
  border: 2px solid var(--main-bg);
  background: var(--main-bg);
  box-shadow: none;
  color: var(--white);
}

.skill-search>input:focus {
  outline: none;
  border: 1px solid #00cccb;
}

.skill-search:before {
  content: "";
  position: absolute;
  top: 8px;
  right: -25px !important;
  background: url(../../asset/image/header/search-icon.svg) no-repeat center;
  width: 25px;
  height: 24px;
}

.headerNavbar-mobileView .skill-search {
  position: absolute;
  right: 10%;
  top: 55px;
  width: 100%;
}

/* background: #1c1c26;
border: 1px solid #00cccb; */

.headerNavbar-mobileView input,
.headerNavbar-mobileView input.form-control:focus,
.headerNavbar-mobileView input.form-control:active {
  background: var(--input-bg);
}

@media screen and (max-width:768px) {
  .skill-search {
    max-width: 700px;
    width: 100%;
  }

  .skill-search input {
    max-width: 700px;
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
    background: var(--main-bg);
    border: 1px solid #00cccb;
  }

  .skill-search:before {
    right: -40px !important;
  }
}