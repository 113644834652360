.policiesPage {
    margin: 45px;
    display: flex;
    flex-direction: column;
}

.polices-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8.33px;
}

.policesMenu-page {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.polices-head h4 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--white);
}

.policies-menu {
    margin-top: 66.7px;
    padding: 10px 0px;
    width: 200px;
    height: 184px;
    background: var(--primary-bg);
    border-radius: 6px;
    display: none;
}

.policies-menu ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    padding-left: 0px;
}

.policies-menu ul li {
    padding: 10px;
}

.policies-menu ul a {
    text-decoration: none;
    border-left: 3px solid transparent;
}

.policies-menu li {
    padding-left: 1rem;
    list-style: none;
    width: 100%;
    font-weight: 400;
    font-size: var(--smallfont);
    line-height: 21px;
    color: var(--white);
}

.policies-menu a.active {
    background-color: var(--input-color);
    cursor: pointer;
    border-left: 3px solid var(--primary-color);
    transition: all 0.4s ease-in-out 0s;
}

.policies-menu ul li:hover {
    color: #CBCBCB;
    cursor: pointer;
}

.policies-menu-mobile {
    margin-top: 20px;
    display: none;
    width: 200px;
    height: 100%;
    border-radius: 6px;
}

.light .polices-head svg path {
    stroke:var(--svg-color);
}

.policies-menu-mobile .dropdown-toggle,
.policies-menu-mobile button {
    background: var(--primary-bg);
    border: none;
    display: flex;
    align-items: center;
    gap: 4px;
    color:var(--white);
}



.policies-menu-mobile button:hover,
.policies-menu-mobile button:active,
.policies-menu-mobile button:focus {
    background: var(--primary-bg);
    border: none;
    color: var(--white);
}

.policies-menu-mobile .dropdown-menu.show {
    margin-top: 20px;
    width: 200px;
    height: 184px;
    border-radius: 6px;
    background: var(--primary-bg);
    box-shadow: 0px 0px 20px 0px #1414188f;
    display: flex;
    flex-direction: column;
}

.policies-menu-mobile .dropdown-menu.show a {
    color: var(--white);
    text-decoration: none;
    padding: 10px;
}

.policies-menu-mobile .dropdown-menu.show a.active {
    background-color: var(--input-color);
    cursor: pointer;
    border-left: 3px solid var(--primary-color);
    transition: all 0.4s ease-in-out 0s;
}

@media screen and (max-width: 1230px) {
    .policesMenu-page {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .policiesPage {
        margin: 20px;
        display: flex;
        flex-direction: column;
    }

    .policies-menu {
        margin-top: 30px;
        display: none;
    }

    .policies-menu-mobile {
        display: none;
    }

}

@media screen and (max-width: 620px) {
    .policiesPage {
        margin: 10px;
        display: flex;
        flex-direction: column;
    }
}