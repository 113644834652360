/* ************************* free coin ******************************* */
.freecoin-content-container {
  padding: 40px 0;
}

.freecoin-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background: var(--primary-bg);
  border-radius: 8px;
  gap: 10px;
}

.freecoin-content-right img {
  max-width: 480px;
  width: 100%;
}

.freecoin-content-left h1 {
  font-weight: 700;
  font-size: var(--largefont);
  line-height: 36px;
  color: var(--white);
  margin: 0px;
  margin-bottom: 15px;
}

.freecoin-content-left h1 span {
  color: var(--fontcolor);
}

.freecoin-content-left {
  max-width: 500px;
  width: 100%;
}

.freecoin-content-left p {
  font-weight: 400;
  font-size: var(--paragraphfont);
  line-height: 30px;
  color: var(--white);
  margin: 0;
  letter-spacing: -0.015em;
}