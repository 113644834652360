.support-container {
    margin: auto;
    padding: 10px;

}

.support-detail {
    /* height: 430px; */
    min-height: calc(100vh - 269px);
    background-color: var(--primary-bg);
    color: var(--light-background);
    border-radius: 10px;
    padding: 10px 10px 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.support-detail-chat {
    height: calc(100vh - 242.22px);
    overflow-y: scroll;
}

/* .message-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

} */
.support-detail-chat .message-wrapper {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 20px;
}

.support-detail-chat .message-wrapper-reverse {
    display: flex;
    flex-direction: row-reverse;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 20px;
}

.support-detail-chat .message-body {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    margin: 0px 10px;
}

.support-detail-chat .chat-user-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.support-detail-chat .user-chat-box {
    padding: 10px 8px;
    margin-left: 8px;

    /* max-width: 372px; */
    /* width: 100%; */
    background: var(--input-color);
    position: relative;
    border-radius: 0px 10px 10px 10px;
}

.support-detail-chat .user-chat-box span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--white);
}

.support-detail-chat .chat-user-name h6 {
    font-weight: 500;
    font-size: var(--smallfont);
    line-height: var(--linkfont);
    display: flex;
    align-items: center;
    color: var(--light-background);
    margin: 0;
}

.support-detail-chat .chat-user-name span.chat-time {
    font-weight: 400;
    font-size: 12px;
    line-height: var(--smallfont);
    color: var(--light-background);
}

.support-detail-chat .message-wrapper-reverse .user-chat-box {
    padding: 10px 8px;
    margin-right: 8px;
    background: var(--input-color);
    position: relative;
    border-radius: 10px 0px 10px 10px;

}

.support-detail-chat .user-chat-box:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-right-color: var(--input-color);
    border-left: 0;
    border-top: 0;
    margin-top: 0;
    margin-left: -12px;
}


.support-detail-chat .message-wrapper-reverse .user-chat-box:after {
    content: "";
    position: absolute;
    left: 386px;
    top: 0;
    width: 0;
    height: 0;
    transform: rotate(270deg);
    border: 15px solid transparent;
    border-right-color: var(--input-color);
    border-left: 0;
    border-top: 0;
    margin-top: 0;
    margin-left: -18px;
}





.support-content {
    background-color: var(--input-color);
    border-radius: 5px;
    margin-top: 25px;
    padding: 5px 10px 0px 10px;
}

.support-bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin: 25px 0px;
}