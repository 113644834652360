.settingPrivacy-page {
    position: relative;
    max-width: 550px;
    width: 100%;
}

.settingPrivacy-privacyContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.settingPrivacy-page h4 {
    font-weight: 500;
    font-size: var(--paragraphfont);
    line-height: 21px;
    margin-bottom: 0px;
    color: var(--white);
}

.settingPrivacy-page h6 {
    font-weight: 400;
    font-size: var(--smallfont);
    line-height: var(--linkfont);
    margin-bottom: 0px;
    color: #E2E8FF;
}

.settingPrivacy-page p {
    font-weight: 300;
    font-size: 12px;
    line-height: var(--smallfont);
    color: #E2E8FF;
    margin-bottom: 0px;
}


.settingPrivacy-togglers {
    margin-top: var(--smallfont);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.settingPrivacy-page .toggle-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.settingPrivacy-toggle-contentText {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.settingPrivacy-saveBtn {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.settingPrivacy-community {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.settingPrivacy-selfExclusion {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.settingPrivacy-saveBtn {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.Self-Exclusion {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

@media screen and (max-width:768px) {
    .settingPrivacy-saveBtn {
        flex-direction: column;
        align-items: flex-start;
    }
}