.header-content {
  position: sticky;
  top: 0;
  z-index: 9;
}

.header-content nav {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  background: var(--sidenav-color);
  padding: 15px 0;
}

.header-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 66px;
}

/* **************************** massege icon *********************** */
.skill-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 20px;
}

.skill-massage {
  padding: 10px;
  cursor: pointer;
}

.helo {
  margin-left: 85px;
}

/* ********************** mobile view navbar *********************** */

.headerNavbar-mobileView {
  display: none;
  justify-content: space-between;
  padding: 0px 0px 0px 5px;
  align-items: center;
  width: 100%;
}

.headerNavbar-mobileView .mobileView-icons img {
  width: 20px;
}

.mobileView-icons {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}

.mobileView-icons .skill-icon a {
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
}

.mobileView-icons .skill-massage {
  /* margin-left: 10px; */
}

.mini-sidebar-btn {
  position: none;
  top: 0px;
  right: 0px;
  /* z-index: 0; */
}

@media screen and (max-width:768px) {
  .header-content-container {
    gap: 45px
  }
}