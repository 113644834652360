.dice-history .table-dark thead th {
  /* background: #1a1c22; */
  background-color: var(--main-bg);
  border-color: transparent;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 26px;
  color: #8d8e92;
}

.bet-box-inp {
  width: 48%;
}

.payout-box-inp {
  width: 32%;
}

.dice-history .table-dark {
  color: #fff;
  margin-top: 15px;
  background: linear-gradient(270.02deg, var(--sidenav-color) 0.02%, var(--sidenav-color) 99.52%);
}

.dice-history .table-dark.table-striped tbody tr {
  border-bottom: 1px solid #363943;
}

.dice-history .table-dark.table-striped tbody tr:last-child {
  border: none;
}

.dice-history .table-dark.table-striped tbody tr:nth-of-type(odd) {
  /* background-color: #3f4040; */
  background-color: #13131b;
}

.dice-history .table-bordered td,
.dice-history .table-bordered th {
  border: none;
}

.dice-history .table-bordered td {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #8d8e92;
  padding: 10px 26px;
  vertical-align: middle;
}

.dice-history .table-dark.table-striped tbody tr td:nth-child(2) {
  /* color: #fff; */
}

.dice-history .table-dark.table-striped tbody tr td:first-child {
  /* color: #fff; */
}

.dice-history .table-bordered td {
  padding: 17px 26px;
}

.dice-history .table-dark.table-striped tbody tr td:last-child {
  /* color: #a8f55c; */
  color: #00cccb;
}

.manual-game,
.automated-game {
  padding: 20px 38px;
  background: var(--main-bg);
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.dice-tab {
  margin-bottom: 14px;
  padding-top: 25px;
}

.dice-tab nav.nav.nav-tabs {
  justify-content: space-between;
  border: none;
}

.nav-item {
  width: 50%;
}

.dice-tab nav.nav.nav-tabs a {
  width: 50%;
  background: #1a1c22;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #8d8e92;
  opacity: 0.5;
  padding: 20px 10px;
  border-color: transparent;
}

.nav-tabs {
  border: none;
}

.nav-link {
  color: var(--white);
}

.nav-tabs .nav-link {
  width: 100%;
  padding: 20px 0px;
  border: none;
}

.dice-tab .nav-tabs .nav-link.active {
  width: 100%;
  /* background: #1a1c22; */
  background-color: var(--main-bg);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #8d8e92;
  border-color: transparent;
  opacity: 1;
}

.dice-tab nav.nav.nav-tabs a:hover {
  border-color: transparent;
  /* color: red */
}

.bet-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.manual-game form label,
.automated-game form label {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #8d8e92;
  margin: 0;
  display: block;
  padding-bottom: 4px;
  padding-top: 10px;
}

.bet-box .form-group {
  width: 49%;
}

.payout-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: var(--sidenav-color);
  border-radius: 3px;
  padding: 12px 15px;
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
/* 
.payout-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #202228;
  border-radius: 3px;
  padding: 12px 15px;
  margin-bottom: 20px;
} */

.payout-box .form-group {
  width: 32%;
}

.roll-dice {
  text-align: center;
}

.light .bet-input .form-control,
.bet-input .form-control:focus {
  color: red;
}

.light .bet-input-btn {
  background: none;
}

.bet-input .form-control,
.bet-input .form-control:focus {
  background: rgba(38, 40, 47, 0.5);
  border: 1px solid #363943;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px 30px;
  height: auto;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  box-shadow: none;
}

.bet-input .form-control::placeholder {
  color: #fff;
}

.bet-input {
  position: relative;
}

.manual-game .form-group,
.automated-game .form-group {
  margin: 0;
}

.bet-input {
  position: relative;
}

.bet-input-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 44px;
  display: flex;
  align-items: center;
  background: #363943;
  border-radius: 0px 3px 3px 0px;
  padding: 10px 12px;
}

.bet-input svg {
  position: absolute;
  left: 15px;
  top: 15px;

}

.light .bet-input svg path {
  fill: #9b8f8f;
}

.bet-input img {
  position: absolute;
  left: 15px;
  top: 15px;
}

.light .bet-input .form-control {
  background: none;
  color: #9b8f8f;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: none;
}

.roll-dice .btn,
.roll-dice .btn:hover,
.roll-dice .btn:focus,
.roll-dice .btn-primary:not(:disabled):not(.disabled):active {
  /* background: #a8f55c; */
  background-color: #00cccb;
  border-radius: 4px;
  max-width: 240px;
  width: 100%;
  border-color: #a8f55c;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #121b24;
  padding: 15px 10px;
  box-shadow: none;
}

.bet-input-btn .btn,
.bet-input-btn .btn:hover,
.bet-input-btn .btn:focus,
.bet-input-btn .btn-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #8d8e92;
  background: transparent;
  border: none;
  border-right: 1px solid rgba(141, 142, 146, 0.25);
  border-radius: 0;
  padding: 0px 12px;
  box-shadow: none;
}

.bet-input-btn .btn:last-child {
  border-right: none !important;
}

.payout-box .bet-input img {
  left: inherit;
  right: 15px;
}

.pdd-200 input {
  padding-right: 200px !important;
}

.roll-slider {
  text-align: center;
  /* background: #202228; */
  background: var(--sidenav-color);
  border-radius: 50px;
  margin-top: 20px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.roll-slider input {
  width: 100%;
}

.roll-slider input[type="range"] {
  overflow: hidden;
  width: 100%;
  -webkit-appearance: none;
  background-color: #00e258;
  border-radius: 30px;
}

.roll-slider input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  -webkit-appearance: none;
  color: #ff5548;
  margin-top: 0px;
}

.roll-slider input[type="range"]::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 30px;
  cursor: ew-resize;
  background: #ffffff;
  box-shadow: -500px 0 0 500px #ff5548;
  position: relative;
}

.transaction-value {
  display: flex;
  align-items: center;
}

/* .automated-game .roll-dice {
  padding-top: 20px;
  padding-bottom: 10px;
} */

.automated-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.automated-option {
  width: 100%;
}

.automated-extra-options {
  width: 100%;
  background: var(--sidenav-color);
  border-radius: 3px;
  padding: 12px 15px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bet-input-radio {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.automated-game .form-group {
  width: 32%;
}

.bet-input-radio .form-check {
  margin-right: 20px;
  padding-left: 25px;
}

.automated-game .form-group.half-w {
  width: 48%;
  margin-bottom: 15px;
}

.bet-input-radio .form-check {
  width: 65% !important;
}

.bet-input .custom-slider.range {
  padding: 15px;
  background: #363943;
  border: 1px solid #363943;
  height: 44px;
  border-radius: 3px;
  margin: 0;
}

.light .bet-input .custom-slider.range {
  background: none;
  color: #9b8f8f;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: none;
}

.light .bet-input-radio .form-check label {
  color: #9b8f8f;
}

.bet-input-radio .form-check-input {
  margin: 0;
  left: 0;
  top: 0px;
  width: 16px;
  height: 16px;
}

.bet-input-radio .form-check label {
  cursor: pointer;
  color: #fff;
  padding-top: unset;
}

.dice-tab-grid .dice-tab nav.nav.nav-tabs a {
  padding: 12px 16px;
  background: #1a1c22;
  font-weight: 500;
  font-size: 12px;
  min-width: 85px;
  width: auto;
}

.dice-tab-grid .dice-tab .nav-tabs .nav-link.active {
  /* background: #1f2127; */
  background: var(--main-bg);
}

.dice-tab-grid .dice-tab nav.nav.nav-tabs {
  justify-content: flex-start;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(54, 57, 67, 0.5);
}

.dice-slider * {
  font-family: "Poppins", sans-serif !important;
}

.manual-game .input-range span.input-range__label.input-range__label--min {
  background: transparent;
  border: none;
}

.dice-slider .input-range__label {
  background: #202228;
  width: 34px;
  border-radius: 5px;
  color: #fff;
  height: 24px;
  line-height: 24px;
  border: 1px solid #202228;
}

.dice-slider .input-range__label--max .input-range__label-container,
.dice-slider .input-range__label-container {
  left: 0;
}

.dice-slider .input-range__label--value {
  left: -15px !important;
  top: -42px !important;
}

.light .dice-slider .input-range__slider {
  background: #202228;
}

.light .roll-slider.dice-slider {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.dice-slider .input-range__slider {
  background: #ffffff;
  border: none;
  margin-top: -14px;
  width: 20px;
  height: 18px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  border-radius: 6px;
  transform: scale(1.3);
}

.dice-slider .input-range__track--active {
  background: #ff5548 !important;
}

.dice-slider .input-range__track {
  background: #00e258;
  border-radius: 20px;
  height: 10px;
}

.roll-slider.dice-slider {
  padding: 20px 30px;
  height: 100px;
  border-radius: 5px;
}

.dice-slider .input-range__label--min,
.dice-slider .input-range__label--max {
  bottom: -1.8rem;
}

.bet-input.roll-input {
  display: flex;
}

.light .bet-input.roll-input span.roll-under {
  border: 1px solid #d1d0d0;
  background: none;
}

.bet-input.roll-input span.roll-under {
  background: #363943;
  display: flex;
  align-items: center;
  width: 50px;
  justify-content: center;
  border-radius: 3px 0px 0px 3px;
  cursor: pointer;
  border: 1px solid #363943;
  margin-right: -1px;
}

.bet-input.roll-input span.roll-under img {
  position: relative;
  top: 0;
  right: 0;
  transition: all 0.5s;
}

.light .manual-game .input-range span.input-range__label.input-range__label--min {
  color: red;
}

.bet-input.roll-input.apply span.roll-under img {
  transform: rotate(-180deg);
}

.automated-game form label span {
  color: #00e258;
}

.input-range__slider-container,
.input-range__track {
  transition: none !important;
}

.dice-slider.flips .input-range__track--active {
  background: #00cccb !important;
}

.dice-slider.flips .input-range__track {
  background: #ff5548;
}

.dice-tab span.input-range__label.input-range__label--min {
  margin-left: -15px;
  padding-top: 3px !important;
}

.dice-tab span.input-range__label.input-range__label--max {
  text-align: right;
}

.result-dice {
  position: absolute;
  top: 0;
  width: calc(100% - 60px);
  pointer-events: none;
}

.nav-tabs .nav-link:hover {
  color: #00cccb
}

.dice-slider .result-dice .input-range__track {
  background: transparent;
}

.dice-tab span.input-range__label.input-range__label--max {
  display: none;
}

.dice-slider .result-dice .input-range__label--min .input-range__label-container,
.dice-slider .result-dice .input-range__label--max .input-range__label-container {
  display: none;
}

.dice-slider .result-dice .input-range__track--active {
  background: transparent !important;
}

.dice-slider .result-dice .input-range__slider {
  background: transparent;
  border: 1px solid transparent;
  width: 80px;
  height: 50px;
  margin-left: -15px;
  border-radius: 5px;
  margin-top: -25px;
}

.dice-slider .result-dice-lose .input-range__label--value {
  background: #f55c5c;
  color: #202228;
  border-color: #f55c5c;
  top: -30px !important;
  width: 80px;
  font-size: 22px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  box-shadow: 2px 3px 10px #00000061;
  left: -40px !important;
}

.dice-slider .result-dice-lose .input-range__label--value:before {
  bottom: -7px;
  border-width: 0.4rem 0.4rem 0;
  position: absolute;
  content: "";
  border-color: transparent;
  border-top-color: #f55c5c;
  border-style: solid;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
}

.dice-slider .result-dice-win .input-range__label--value {
  background: #a9f55c;
  color: #202228;
  border-color: #a9f55c;
  top: -30px !important;
  width: 80px;
  font-size: 22px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  box-shadow: 2px 3px 10px #00000061;
  left: -40px !important;
}

.dice-slider .result-dice-win .input-range__label--value:before {
  bottom: -7px;
  border-width: 0.4rem 0.4rem 0;
  position: absolute;
  content: "";
  border-color: transparent;
  border-top-color: #a9f55c;
  border-style: solid;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
}

.dice-slider .result-dice .input-range__label--min,
.dice-slider .result-dice .input-range__label--max {
  background: transparent;
}


::placeholder {
  color: rgba(184, 184, 184, 0.39) !important;
}

.roll-under-text {
  color: rgb(224, 69, 69) !important;
  font-weight: bold !important;
}

.roll-over-text {
  color: green !important;
  font-weight: bold !important;
}

.stop-auto-spins-btn {
  background: rgb(224, 69, 69) !important;
  border-color: rgb(224, 69, 69) !important;
}


/*********** Previous Result **************/

.previous-result-grid {
  display: flex;
  margin-bottom: 56px;
  position: relative;
  justify-content: space-between;
  z-index: 1;
}

.previous-result-container {
  display: flex;
  overflow-x: scroll;
  width: calc(100% - 130px);
}

.previous-result-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.previous-result-container::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.previous-result-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.previous-result-box {
  padding: 11px 10px;
  background: #23252B;
  margin-right: 5px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  position: relative;
  height: 37px;
}

.previous-result-box span {
  display: block;
  position: relative;
  z-index: 9;
}

.result-loss {
  /* color: #E72649; */
  color: #9a9697;
  /* background: rgba(231, 38, 73, 0.15); */
}

.result-gain {
  color: #16181E;
  background: #00cccb;
}

.result-gain:after {
  /* background: #ccff8c; */
  content: "";
  width: 30px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 10%;
  -webkit-clip-path: polygon(55% 0, 100% 0, 45% 100%, 0 100%);
  clip-path: polygon(55% 0, 100% 0, 45% 100%, 0 100%);
}


.change-seed-btn {
  background: #23252B;
  border-radius: 3px;
  padding: 5px 13px;
  height: 37px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

.change-seed-btn a,
.change-seed-btn a:hover {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #8D8E92;
  text-decoration: none;
}

.change-seed-btn a img {
  margin-right: 5px;
}


.custom-slider.range {
  margin: 0px 15px;
}

.custom-slider input[type="range"] {
  overflow: hidden;
  width: 100%;
  -webkit-appearance: none;
  background-color: #23252c;
  border-radius: 30px;
  padding: unset;
}

.custom-slider input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #00e258;
  margin-top: 0px;
}

.custom-slider input[type="range"]::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: #ffffff;
  box-shadow: -500px 0 0 500px #00e258;
  position: relative;
}

@media screen and (max-width:991px) {
  .bet-box {
    flex-direction: column;
  }

  .bet-box-inp {
    width: 100%;
  }

  .payout-box {
    flex-direction: column;
  }

  .payout-box-inp {
    width: 100%;
  }

}