.modal-backdrop {
  background-color: #3b3b3b !important;
  opacity: 1 !important;
  background: rgba(13, 14, 18, 0.75) !important;
}


@media screen and (max-width: 1440px) {
  .items-grid {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .all-result-sec {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }

  .result-box {
    min-width: 180px;
  }

  /* .mines-game-sec{
      display: grid;
      grid-template-columns: repeat(3, minmax(0px, 1fr));
  } */
  .all-live-games .result-box {
    max-width: 100%;
  }
}

@media screen and (max-width: 1366px) {
  .items-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1200px) {
  .header-top-area .navbar-expand-lg .navbar-nav .nav-link span {
    font-size: 13px;
  }

  .header-top-area .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px 14px;
  }

  .items-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 991px) {

  /**** footer ******/
  .dice-slider .result-dice .input-range__label--value {
    top: -24px !important;
    width: 65px;
    font-size: 18px;
    height: 44px;
    left: -25px !important;
  }

  .sticky-footer {
    display: flex !important;
  }

  .chat-wrapper {
    left: -100%;
    z-index: 999;
  }

  .content {
    width: 100% !important;
    margin-left: 0 !important;
    padding: 0px 15px;
  }

  .profile-menu {
    min-height: auto;
    padding: 20px 10px;
  }

  .header-logo img {
    width: 105px;
  }

  .header-top-area {
    min-height: 62px;
    padding: 0px 28px;
  }

  .header .header-logo-menu {
    justify-content: flex-start;
    width: 130px;
  }

  .hide-btn {
    position: fixed;
    right: 20px;
    z-index: 99;
    background: #1f2127;
    border: none;
    transform: rotate(180deg);
    /* display: none; */
    margin-top: -20px;
  }

  .chat h2::before {
    top: 16px;
  }

  .chat-wrapper.chat-hide .hide-btn {
    background: #1f2127;
    border: none;
    right: 20px;
    left: inherit;
  }

  .hide-btn i {
    display: none;
  }

  .hide-btn i.mobile-icon {
    display: block;
    transform: rotate(180deg);
    position: relative;
    top: 7px !important;
    color: #f5d35c;
    font-size: 14px;
  }

  .chat-hide .hide-btn i.mobile-icon {
    transform: rotate(0deg);
  }

  .chat-wrapper.chat-hide {
    left: 0;
  }

  .main-game-list {
    width: calc(100% - 200px);
    justify-content: flex-end;
  }

  .header-logo {
    width: 105px;
  }

  .navbar-light .navbar-toggler-icon {
    display: none !important;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed {
    padding: 0;
    border: none;
    color: transparent;
    position: relative;
    width: 25px;
    height: 25px;
    border-top: 2px solid #8d8e92;
    border-radius: 0;
    top: 5px;
  }

  .header-top-area .navbar-light .navbar-toggler {
    padding: 0;
    border: none;
    color: transparent;
    position: relative;
    width: 25px;
    height: 25px;
    border-top: 2px solid transparent;
    border-radius: 0;
    top: 2px;
  }

  .header-top-login {
    display: none;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed::before {
    content: "";
    background: #8d8e92;
    width: 15px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    transition: 0.4s all ease;
    transform: inherit;
  }

  .header-top-area .navbar-light .navbar-toggler.collapsed::after {
    content: "";
    background: #8d8e92;
    width: 25px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 16px;
    right: 0;
    transition: 0.4s all ease;
    transform: inherit;
  }

  .header-top-area .navbar-light .navbar-toggler::before {
    content: "";
    background: #8d8e92;
    width: 25px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    transition: 0.4s all ease;
    transform: rotate(-45deg);
  }

  .header-top-area .navbar-light .navbar-toggler::after {
    content: "";
    background: #8d8e92;
    width: 25px;
    height: 2px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 8px;
    right: 0;
    transition: 0.4s all ease;
    transform: rotate(45deg);
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    background: #1f2127;
    bottom: 0;
    width: 300px;
    border-right: 1px solid #252831;
    left: -300px;
    transition: 0.4s all ease;
    z-index: 999;
  }

  .navbar-collapse.show {
    left: 0;
  }

  .header-top-area .navbar-expand-lg .navbar-nav .nav-link {
    padding: 20px 30px;
  }

  .header-top-area .navbar-expand-lg .navbar-nav .nav-link:first-child {
    padding-left: 30px;
  }

  .header-top-area .navbar-light .navbar-nav .nav-link.active span::after {
    display: none;
  }

  .header-top-area .navbar-light .navbar-nav .nav-link.active {
    color: #f5d35c;
  }

  .mobile-login {
    position: fixed;
    bottom: 30px;
    left: 30px;
  }

  .header-top-login.mobile-login ul {
    display: flex;
    justify-content: center;
  }

  .header-top-login ul li span {
    font-size: 14px;
    padding: 8px 10px;
    min-width: 108px;
  }

  .navbar-nav {
    padding-top: 10px;
  }

  .header-logo-mobile {
    display: block;
    padding-left: 30px;
    padding-bottom: 25px;
    padding-top: 10px;
  }

  .show .mobile-login {
    display: block;
  }

  .header-logo-mobile img {
    max-width: 105px;
  }

  .get-credit-box {
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    right: 0;
  }

  .credit-box {
    padding: 5px 5px;
    width: 20%;
  }

  .credit-container {
    flex-wrap: wrap;
  }

  .credit-input .form-control {
    position: relative;
    height: 40px !important;
    padding: 8px 10px;
    padding-left: 50px;
  }

  .header .credit-input .form-control {
    height: auto !important;
  }

  .credit-amount {
    padding: 5px;
    width: 100%;
  }

  .predifined-bet {
    padding: 0px 0px;
  }

  .place-bet-container {
    flex-wrap: wrap;
  }

  .place-bet-box {
    width: 100%;
    margin: 10px 0px;
  }

  .place-bet-box.one-third {
    width: 100%;
  }

  .place-bet-box.one-third {
    margin-bottom: 5px;
  }

  .bet-header .btn {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px 8px;
  }

  .previous-result-content {
    flex-direction: column;
  }

  .previous-result-content .previous-game {
    margin-bottom: 10px;
  }

  .bet-header h4 {
    justify-content: space-between;
    font-size: 15px;
  }

  .bet-header h4 .bet-box {
    margin: 0;
    margin-bottom: 0px;
    display: block;
  }

  .hide-listing .bet-list ul {
    display: none;
  }

  .bet-list-winner span.mob-toggle-icon {
    display: flex !important;
  }

  .bet-header span {
    font-size: 14px;
    padding-top: 5px;
    /* display: none; */
  }

  .bet-header h4 span {
    color: #fff;
    display: block;
    width: 100%;
    padding-top: 0px;
    text-align: center;
    font-size: 15px;
  }

  .bet-header h4 img {
    margin-right: 2px;
    margin-bottom: 0px;
    width: 20px;
  }

  .bet-amount .gray:before {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #d8d8d8;
    border-radius: 5px;
    margin-right: 10px;
  }

  .bet-amount .green:before {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #298854;
    border-radius: 5px;
    margin-right: 10px;
  }

  .bet-amount .cream:before {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #f5d35c;
    border-radius: 5px;
    margin-right: 10px;
  }

  .bet-amount span {
    color: #fff;
  }

  .profile-sidebar,
  .profile-content {
    width: 100%;
  }

  .roulette_section {
    width: auto;
  }

  .roulette {
    width: 600px;
    transform: inherit;
    margin: 0px auto;
  }

  .items-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;
  }

  .search-grid form {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .search-grid form .input-img.form-group {
    width: 100%;
    max-width: 100%;
  }

  .search-grid form .form-group {
    margin-right: 0;
    width: 48%;
  }

  .manual-game,
  .automated-game {
    padding: 20px 20px;
  }

  .bet-box .form-group {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .pdd-200 input {
    padding-right: 100px !important;
  }

  .bet-input-btn .btn,
  .bet-input-btn .btn:hover,
  .bet-input-btn .btn:focus,
  .bet-input-btn .btn-primary:not(:disabled):not(.disabled):active {
    padding: 0px 8px;
  }

  .bet-input-btn {
    padding: 10px 5px;
  }

  .payout-box .form-group {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .bet-box {
    margin-bottom: 10px;
  }

  .profile-menu .nav {
    justify-content: space-between;
  }

  .profile-menu .nav a {
    width: 48%;
    margin: 5px 0px;
  }

  .form-input .form-control,
  .form-input .form-control:focus {
    width: 100%;
  }

  .grid-expand .items-grid {
    width: calc(100%);
  }

  .social-btns {
    flex-wrap: wrap;
  }

  .social-btns .btn {
    width: 100%;
    margin-bottom: 10px;
    font-size: 13px;
    padding: 8px;
  }

  .verify-content p,
  .verify-content ul li {
    font-weight: normal;
    font-size: 13px;
  }

  .mbl-deposit {
    display: block;
  }

  .mbl-deposit input.form-control,
  .mbl-deposit input.form-control :focus {
    width: 100px;
    padding: 10px;
    padding-left: 30px;
    height: 34px !important;
    pointer-events: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .credit-input.mbl-deposit form {
    display: flex;
  }

  .credit-input.mbl-deposit a {
    position: relative;
    background: #a9f55c;
    border-radius: 3px;
    border-color: #192633;
    height: 34px;
    line-height: 34px;
    width: 34px;
    text-align: center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .credit-input.mbl-deposit a img {
    position: relative;
    left: inherit !important;
    top: inherit;
    transform: inherit;
  }

  .credit-input.mbl-deposit form img {
    width: 10px;
    left: 10px;
  }

  .mbl-deposit {
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
  }

  .nav-link.menu-mbl {
    display: block;
  }

  .selected-item-grid {
    max-height: 350px;
    overflow-y: auto;
  }

  .automated-game .form-group.half-w {
    width: 100%;
  }

  .automated-game .form-group {
    width: 100%;
    margin-bottom: 15px;
  }

  .automated-game .form-group:last-child {
    margin: 0;
  }

  .credit-pay h5 {
    color: #9fafbd;
  }

  .credit-input .form-control::placeholder,
  .credit-input .form-control,
  .search-grid form input::placeholder,
  .search-grid form input {
    color: #9fafbd;
  }

  .dice-tab-grid .dice-tab nav.nav.nav-tabs a {
    max-width: 50%;
    font-size: 12px;
  }

  .nav-item {
    width: 50%;
  }

  /******* mobile view sidebar ***********/
  .sidebar-wrapper {
    width: 257px;
    background: #1a1c22;
    position: fixed;
    left: -257px;
    transition: all ease 0.5s;
    margin-top: -6px;
  }

  .sidebar-content {
    /* height: calc(100vh - 132px);
    overflow: auto;
    padding: 11px 14px; */
  }

  .footer .footer-top {
    padding: 32px 27px;
  }

  .footer .footer-top,
  .footer .copyright-text {
    /* padding: 0px 27px; */
  }

  .footer .footer-menu {
    padding-left: 0px;
  }

  .footer .footer-logo ul {
    position: absolute;
    top: 38px;
    right: 30px;
    display: flex;
  }

  .footer {
    padding-bottom: 75px;
  }

  .wrapper.opensidebar .sidebar-wrapper {
    left: 0px;
  }

  .header .header-logo-menu .menu-icon,
  .header .header-logo-menu .search-icon,
  .header .my-profile-menu {
    display: none;
  }

  .chat-wrapper {
    width: 100%;
    margin-top: -8px;
  }

  .chat-box {
    height: calc(100vh - 280px);
    margin-bottom: 0px;
  }

  .chat-footer {
    padding: 16px 14px;
  }

  /****home page ********/
  .home-rubyroll {
    padding-top: 33px;
  }

  .home-rubyroll-cover img {
    height: 142px;
  }

  .home-rubyroll-grid {
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .home-casino {
    padding: 33px 0px 82px;
  }

  .home-casino-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .dice-history .table-bordered td {
    white-space: nowrap;
  }

  /**** roulette page *******/
  .roulette {
    height: 85px;
    background-size: auto 67px;
  }

  /** affiliate ******/
  .referral-box .form-group {
    width: 100%;
  }

  .wagered-box,
  .claim-box {
    width: 100%;
    margin-bottom: 7px;
  }

  .referral-box {
    padding: 19px 20px 13px;
    margin-bottom: 18px;
  }

  .affiliate-page .dice-history .table-bordered td {
    padding: 15px 50px 15px 27px;
  }

  .cashier-modals .modal-header .close,
  .qr-modal .modal-header .close {
    right: 0;
    top: -40px;
  }

  /***** search page*********/
  .all-result-sec {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }

  .search-heading form {
    max-width: 100%;
    width: 100%;
  }

  /******* live games ******/
  .games-header .games-heading {
    width: 100%;
    max-width: 100%;
    margin-bottom: 17px;
  }

  .games-search-box {
    max-width: 100%;
    width: 100%;
  }

  .livegames-page {
    padding-top: 105px;
  }

  .top-games-heading h2 {
    font-size: 16px;
    line-height: 20px;
  }

  .games-heading p {
    margin-left: 0;
  }

  .games-header {
    width: calc(100% + 54px);
    left: -27px;
    right: -27px;
    padding: 31px 27px;
  }

  /**** footer *****/
  .footer {
    margin-left: -27px;
    margin-right: -27px;
  }

  /****** mines game ********/
  .mines-game-sec {
    grid-template-columns: repeat(5, minmax(0px, 1fr));
    grid-gap: 7px;
    grid-gap: 7px;
    gap: 7px;
    max-width: calc(100% - 0px);
    padding: 13px 13px 45px;
    width: 100%;
  }

  .mines-game-tiles {
    max-height: 77px;
    height: 77px;
    width: 100%;
    max-width: 77px;
  }

  .mines-game .mines-game-tiles.active-game-tile img {
    height: 35px;
    width: 36px;
  }

  .active-game-tile p {
    display: none !important;
  }

  .mines-tab {
    max-width: 100%;
    width: 100%;
    padding: 46px 16px 25px;
  }

  .mines-game {
    flex-direction: column-reverse;
  }

  .mines-tab {
    margin-bottom: 50px;
  }

  .mines-tab .roll-dice button {
    max-width: 276px !important;
    width: 100% !important;
    height: 50px !important;
    z-index: 99999;
  }

  .mines-tab .roll-dice {
    z-index: 9;
  }

  .mines-tab .roll-dice {
    position: absolute;
    top: -125px;
    width: 100%;
    margin: 0px auto !important;
    justify-content: center;
  }

  .mines-tab .change-seed {
    bottom: -90px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .manual-tab {
    position: relative;
  }

  .mines-right-section {
    max-width: 100%;
  }

  .mines-game-tiles img {
    height: 37px;
    width: 37px;
  }

  /******* tower game *********/
  .tower-page .level-btn button {
    width: 33% !important;
    max-width: 33% !important;
  }

  /****** bonanaz page *********/
  .bonanza-header .right-bonanza-header,
  .bonanza-header .demo {
    width: fit-content;
  }
}

@media screen and (max-width: 480px) {
  .mines-game-tiles {
    max-height: 60px;
    height: 60px;
    width: 100%;
    max-width: 77px;
  }

  .roulette {
    width: 375px;
    transform: inherit;
    margin: 0px auto;
  }

  .roll-slider.dice-slider {
    padding: 20px 10px;
  }

  .home-slider-space {
    margin: 0px 5px;
  }

  .home-slider-content .slick-prev:before,
  .home-slider-content .slick-next:before {
    width: 24px;
    height: 24px;
  }

  .home-slider-content .slick-prev,
  .home-slider-content .slick-next {
    width: 24px;
    height: 24px;
  }

  .home-slider-content .slick-prev {
    left: 10%;
  }

  .home-slider-content .slick-next {
    right: 10%;
  }
}

@media screen and (max-width: 360px) {
  .items-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .footer .footer-top {
    padding: 32px 27px 40px;
  }

  .footer-logo p {
    padding: 24px 0 47px;
  }

  .copyright-text p {
    padding: 22px 0 29px;
  }
}

@media screen and (max-width: 340px) {
  .navbar-collapse {
    width: 260px;
  }

  .header-top-login ul li span {
    font-size: 13px;
    padding: 8px 10px;
    min-width: 90px;
  }

  .header-top-area .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
  }

  .chat-wrapper.chat-hide .hide-btn {
    left: 248px;
  }

  .credit-box {
    width: 25%;
  }

  .get-credit-box .credit-box:last-child {
    width: 100%;
  }

  .credit-pay h5 {
    font-size: 14px;
  }

  .selected-item-grid {
    max-height: 200px;
    overflow-y: auto;
  }
}