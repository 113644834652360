.faqPage {
    padding: 2rem;
}

.skill-faq-head {
    align-items: center !important;
}

.skill-faq-body p {
    
}