.roulette-wrapper {
  width: 100%;
  min-height: calc(100vh - 140px);
  border-radius: 5px;
  padding-top: 25px;
}

.roulette-wrapper img {
  width: 100%;
  margin: 0px auto;
  max-width: 500px;
  display: block;
}

.roulette_section {
  /* width: 1120px; */
  margin: 0px auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 5px;
  /* background: #16181e; */
  background: var(--main-bg);
  overflow: hidden;
  margin-top: 10px;
}

.roulette {
  display: block;
  background: url(../../../assets/images/game/bg.png);
  /* width: 1120px; */
  /* width: 100%; */
  height: 90px;
  background-size: auto 90px;
  background-repeat: repeat-x;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: ease 0.5s;
  transform: translateX(-36px);
}

.roulette .item {
  display: inline-block;
  vertical-align: middle;
  height: auto;
  border: none !important;
  background-image: linear-gradient(to right, #1a16221a, #1a16221a);
  margin: 0px 0px;
}

.roulette_wrapper {
  overflow: hidden;
  position: relative;
  height: 100px;
  width: 100%;
}

.roulette_marker {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #87ceff;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
}

.roulette_container .game-bet-box {
  background: #fff;
  padding: 5px 5px;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  margin: 0px 2px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  color: #000;
  cursor: pointer;
}

.roulette_container .game-bet-box.green-box {
  background: #318d36;
}

.roulette_container .game-bet-box.cream-box {
  background: #6d24a0;
}

.roulette_container .game-bet-box.gray-box {
  background: #df3420;
}

.bet-box.gray {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #d8d8d8;
  border-radius: 5px;
  margin-right: 10px;
}

.bet-box.green {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #298854;
  border-radius: 5px;
  margin-right: 10px;
}

.bet-box.cream {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #f5d35c;
  border-radius: 5px;
  margin-right: 10px;
}

.roulette_container .game-bet-box h4 {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: #fff;
}

.roulette_container .game-bet-box.gray-box h4 {
  color: #000;
}

.button_wrapper {
  margin-top: 30px;
}

.roulette_container {
  position: relative;
  width: 100%;
  padding: 30px 0px;
  z-index: 1;
}

.light .credit-pay h5 {
  color: #16171c;

}

.roulette_logo {
  width: 100px;
  padding: 2px 10px;
  cursor: pointer;
}

.roulette_logo img {
  width: 100%;
}

.roulette_group_type {
  width: 100px;
  padding: 2px 10px;
  text-align: center;
}

.roulette_group_type h5 {
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}

.roulette_group_type span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #191e24;
  line-height: 1;
}

.spin-button {
  text-align: center;
  padding: 30px 0px;
  padding-top: 0;
}

.spin-button .l-btn.btn.btn-primary {
  max-width: 120px;
  font-weight: 500;
  font-size: 15px;
  padding: 12px 20px;
  border-radius: 6px;
}

.roulette-container-line--bottom {
  bottom: 0;
}

.roulette-container-line--top {
  top: 0;
}

/* .roulette-container-line--bottom,
.roulette-container-line--top {
  position: absolute;
  left: 0;
  background-color: #f5d35c;
  right: 0;
  height: 2px;
  width: 100%;
  transition: background-color 0.4s ease-in;
} */

.roulette-container-line--mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #191c24 3%, transparent, #191c24 97%);
}

.roulette-container-shadow--left,
.roulette-container-shadow--right {
  z-index: 1000;
  position: absolute;
  top: 50%;
  bottom: 0;
  background: linear-gradient(90deg, rgb(18 27 36), transparent);
  height: 102px;
  width: 150px;
  opacity: 0.8;
  transform: translateY(-50%);
}

.roulette-container-shadow--left {
  left: 0;
}

.roulette-container-shadow--right {
  right: -1px;
  transform: rotate(180deg) translateY(50%);
}

.roulette-indicator {
  position: absolute;
  height: 90px;
  width: 2px;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  background: linear-gradient(0deg, #b67c26, #b67c26),
    linear-gradient(180deg, #532222 0%, #ff4a4a 51.56%, #532222 100%);
}

/* .roulette-indicator--top {
  transform: translate(-50%, -50%) rotate(90deg);
  top: 5px;
} */

.roulette-indicator svg {
  height: 100%;
  width: auto;
}

.roulette-indicator--bottom {
  transform: translate(-50%, 50%) rotate(-90deg);
  bottom: 5px;
}

.predifined-bet {
  padding: 30px 0px;
  border-radius: 5px;
}

.get-credit-box {
  display: flex;
  position: absolute;
  right: 10px;
}

.credit-box {
  text-align: center;
  position: relative;
  padding: 0px 5px;
  cursor: pointer;
}

.credit-input .form-control,
.credit-input .form-control:focus {
  background: white;
}

.credit-input .form-control,
.credit-input .form-control:focus {
  background: #2c3038;
  border: 1px solid #3a3e48;
  color: #ddd;
  box-shadow: none;
}

.credit-pay h5 {
  margin: 0;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #8d8e92;
}

.credit-pay p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #fff;
}

.credit-box input[type="radio"] {
  position: absolute;
  width: 100%;
  height: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  cursor: pointer;
  outline: none;
  border: none;
}

.get-credit .order-box h2+p {
  padding-bottom: 30px;
}

.light .credit-box input[type="radio"]+.credit-pay {
  background: #19b5db;
}

.credit-box input[type="radio"]+.credit-pay {
  background: #26282f;
  border-radius: 4px;
  padding: 10px 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.credit-box input[type="radio"]:checked+.credit-pay {
  background: #43464f;
  color: #8d8e92;
}

.credit-box input[type="radio"]:checked+.credit-pay h5 {
  color: #8d8e92;
}

.credit-box input[type="radio"]:checked {
  outline: none;
}

.get-credit .order-box .btn.btn-style-two::before,
.get-credit .order-box .btn.btn-style-two::after {
  background: #f5d35c;
}


.credit-input .form-control,
.credit-input .form-control:focus {
  color: #8d8e92;
  box-shadow: none;
  background: rgba(38, 40, 47, 0.5);
  border: 1px solid #363943;
  box-sizing: border-box;
  border-radius: 4px;
  height: 56px;
}

.credit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: var(--main-bg); */
}

.light .credit-container {
  background-color: white;
}

.credit-amount {
  width: 100%;
  z-index: 1;
}

.credit-input .form-control {
  padding-left: 50px;


}

.credit-input form {
  position: relative;
}

.credit-input form img {
  width: 20px;
  position: absolute;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}

.light .credit-input .form-control {
  color: black;
  background: white;
  border: 1px solid #d5d5d5;
}

/* .light .credit-input .form-control::placeholder {
  color: white;
} */

.credit-input .form-control {
  padding-left: 50px;
  position: relative;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: #8d8e92;
}

.light .credit-input .form-control::placeholder {
  color: black;
}

.credit-input .form-control::placeholder {
  color: #8d8e92;
}

.credit-input .form-control::before {
  content: "C";
  display: block;
}

.credit-input form img {
  width: 14px;
  position: absolute;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  left: 22px;
}

.place-bet-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 100px;
  flex-wrap: wrap;
}

.bet-list ul {
  height: 340px;
  overflow-x: hidden;
  overflow-y: auto;
}

.light .bet-list ul::-webkit-scrollbar-track {
  background-color: #00cccb;
}

.bet-list ul::-webkit-scrollbar-track {
  background-color: #16181e;
}

.light .bet-header span {
  color: #3e3e3e;
}

.bet-list ul::-webkit-scrollbar {
  width: 3px;
  background-color: #16181e;
}

.bet-list ul::-webkit-scrollbar-thumb {
  background-color: #1f2127;
}

.place-bet-box {
  width: 32%;
}

.place-bet-box.one-third {
  margin-bottom: 5px;
}

.bet-list {
  background: #13131b;
  position: relative;
}

.bet-header h4 span {
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  font-weight: 400;
}

.bet-list:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 4rem;
  width: 100%;
  opacity: 1;
  border-radius: 5px;
  -webkit-transition: opacity var(--global-transition);
  transition: opacity var(--global-transition);
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(0),
      color-stop(0, #16171c),
      to(transparent));
  background: linear-gradient(0, #16171c, transparent);
  pointer-events: none;
}

.light .bet-header {
  border-bottom: none;
}

.light .bet-list ul li:nth-of-type(even) {
  background: none;
}

.bet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, #292e33 0%, #2c3238 100%);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
  border-bottom: 2px solid #1f2329;
}

.bet-header:hover {
  background: linear-gradient(90deg, #292e33 0%, #2c3238 100%);
}

.light .bet-header .btn {
  background-color: #00cccb;
}

.bet-header .btn {
  width: 100%;
  display: flex;
  padding: 15px 15px;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-color: transparent;
}

.bet-header .btn:hover,
.bet-header .btn:focus,
.bet-header .btn-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  border-color: transparent;
  box-shadow: none !important;
}

.bet-header span {
  color: #8d8e92;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
}

.bet-header h4 {
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.bet-amount {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #bcbcbc;
  justify-content: space-between;
  padding: 14px 12px;
  /* background: linear-gradient(270deg, #1e2326 0%, rgba(30, 35, 38, 0) 100%); */
  background: transparent;
}

.bet-list ul li:nth-of-type(even) {
  background: linear-gradient(270deg, #1e2326 0%, rgba(30, 35, 38, 0) 100%);
}

.bet-amount span img {
  width: 10px;
  margin-right: 7px;
}

.bet-amount span {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #8d8e92;
}

/* .bet-header.blue-head .place {
  color: #d796ff;
}
.bet-header.red-head .place {
  color: #fee38b;
}
.bet-header.green-head .place {
  color: #cfff92;
} */

.light .bet-list {
  background: none;
}

.light .bet-list:after {
  background: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.light .bet-list ul li {
  border: 1px solid rgb(197, 197, 197);
}

.bet-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  font-size: 12px;
  color: #bcbcbc;
  border-top: 1px solid #1f2428;
}

.bet-user-amount {
  color: #8d8e92;
  display: flex;
  align-items: center;
}

.bet-user-amount img {
  width: 10px;
  margin-right: 5px;
}

.bet-user {
  display: flex;
  align-items: center;
}

.bet-user img {
  width: 30px;
  margin-right: 10px;
  border: 1px dashed #414251;
  height: 30px;
  object-fit: cover;
  border-radius: 100%;
}

.login-for-play h2 {
  color: #fff;
  text-align: center;
}

.login-for-play p {
  color: #ddd;
  text-align: center;
}

.login-for-play .l-btn {
  max-width: 120px;
  margin: 0px auto;
  display: block;
  margin-bottom: 30px;
  padding: 8px 20px !important;
}

.rolling {
  position: absolute;
  z-index: 9999;
  left: 50%;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  color: #f5d35c;
}

/* .disable-roulette:before {
  content: "";
  position: absolute;
  background: #1A1C22d6;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
} */

.disable-roulette .roulette {
  opacity: 1;
}

.rolling span {
  display: block;
}

.rolling h2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
}

.rolling h2 span {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  display: inline-block;
}

.previous-result-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* 
.previous-game {
  display: flex;
} */

.last-game {
  display: flex;
  align-items: center;
}

.previous-game img {
  width: 25px;
}

.previous-rolls-item {
  margin-right: 3px;
}

.last-game img {
  width: 25px;
}

.last-label {
  margin-right: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #8d8e92;
}

.label-coin {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #ffffff;
  margin-left: 3px;
}

.last-item {
  margin-left: 8px;
}

.bet-header h4 img {
  width: 30px;
  margin-right: 10px;
}

.bet-user h5 {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  margin: 0;
}

span.yellow-badge {
  background: #f5d35c;
  border-radius: 2px;
  display: inline-block;
  width: 26px;
  height: 16px;
  line-height: 17px;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  color: #1b1f22;
  margin-right: 8px;
}

span.pink-badge {
  background: #e95cf5;
  border-radius: 2px;
  display: inline-block;
  width: 26px;
  height: 16px;
  line-height: 17px;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  color: #1b1f22;
  margin-right: 8px;
}

span.blue-badge {
  background: #5cacf5;
  border-radius: 2px;
  display: inline-block;
  width: 26px;
  height: 16px;
  line-height: 17px;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  color: #1b1f22;
  margin-right: 8px;
}

.active .blue-head {
  border-bottom: 2px solid #d796ff;
}

.active .red-head {
  border-bottom: 2px solid #fee38b;
}

.active .green-head {
  border-bottom: 2px solid #cfff92;
}

.Toastify__toast-theme--dark {
  background: linear-gradient(270deg, #15222e 0%, #1e2e3e 100%);
}

.Toastify__toast-body {
  font-family: "Poppins", sans-serif !important;
  font-weight: normal;
  font-size: 14px;
}

.Toastify__progress-bar--success {
  background: #a8f55c;
}

.Toastify__progress-bar--error {
  background: #ee484e;
}

.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #a8f55c;
}

.Toastify__toast--error .Toastify__toast-icon svg {
  fill: #ee484e;
}

.prevoius-gmae-list {
  display: flex;
}

.prevoius-content p {
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #8D8E92;
  margin-bottom: 10px;
}

.bet-list.bet-list-winner img {
  height: 41px;
  width: 41px;
}

.bet-list.bet-list-winner ul {
  height: auto;
  display: block !important;
}

.bet-user-section {
  display: flex;
}

.bet-user-amount img {
  height: 10px !important;
  width: 10px !important;
  margin: 0px;
  margin-right: 4px;
}

.bet-list.bet-list-winner li {
  background: linear-gradient(270deg, #1E2326 0%, rgba(30, 35, 38, 0) 100%);
  padding: 15px 12px !important;
  margin: 23px 0px 0px;
}

.bet-list-winner span.yellow-badge {
  margin-left: 8px;
  margin-right: 0px;
}

.bet-list-winner .bet-user-amount {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
}

.bet-list-winner span.mob-toggle-icon {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background: #2C3237;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  display: none;
  justify-content: center;
  align-items: center;
  margin-left: 12px;

}

.bet-list-winner span.mob-toggle-icon img {
  margin: 0px !important;
  width: 6px !important;
  height: 4px;
  transform: rotate(180deg);
}

@media screen and (max-width: 1270px) {
  .roulette_section {
    width: auto;
  }
}

@media screen and (max-width: 991px) {
  .get-credit-box {
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    right: 0;
  }

  .credit-box {
    padding-top: 5px;
  }

  .roulette_section {
    width: auto;
  }
}