.loginHome-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color:var(--login-bg) ;
}

.loginHome-img img {
    max-width: 540px;
    width: 100%;
    /* background-color:var(--login-bg) ; */
}

@media screen and (max-width:900px) {
    .loginHome-img {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
    }

    .loginHome-img img {
        opacity: 0.1;
        max-width: 100%;
        z-index: 1;
        background-color:var(--login-bg);
    }
}