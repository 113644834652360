.name {
    margin-top: var(--linkfont);
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.reg-form .form-control .error-input {
    border: 1px solid #D24E4E;
}

.reg-form-dob {
    color: #8f8fa3 !important;
}

.login-content .reg-form {
    width: 360px;
    margin: 40px 0px 20px 0;
}

.reg-form .form-control {
    background: var(--input-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: var(--placeholder-color);
    height: 44px;
    font-size: var(--linkfont);
}

.reg-form .reg-mail svg path {
    stroke: var(--light-background);
}

.reg-form input::placeholder {
    color: var(--secondary-color);
    font-weight: 400;
    font-size: var(--linkfont);
    line-height: 19px;
    opacity: 0.5;
}


.reg-form .form-label {
    font-weight: 400;
    font-size: 12px;
    line-height: var(--smallfont);
    color: var(--white);
}

.reg-form h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: var(--white);
}

.reg-form span {
    color: #D24E4E;
    display: inline;
}

.reg-form .reg-DOB {
    margin-top: var(--linkfont);
    color: var(--white);
    display: flex;
    flex-direction: column;
}

.reg-form .reg-mail {
    margin-top: var(--linkfont);
    position: relative;

}

.reg-form .reg-mail img {
    position: absolute;
    left: 13.3px;
    top: 42px;
}

.reg-form .reg-mail svg {
    position: absolute;
    left: 13.3px;
    top: 42px;
}

.reg-form .reg-mail .form-control {
    padding-left: 35px;
}


.login-content .loginBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--largefont);
    padding: 10px 24px;
    border-radius: 30px;
    height: 40px;
    max-width: 360px;
    width: 100%;

    border: none;
    background: var(--primary-color);
    color: var(--white);
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 700;
    line-height: 20px;
}

.login-content .loginBtn:active {
    background: var(--primary-color) !important;
}

.login-content .regBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: var(--largefont);
    gap: 8px;
    padding: 10px var(--linkfont);
    border-radius: 30px;
    height: 40px;
    max-width: 360px;
    width: 100%;
    background: var(--input-color);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    color: var(--placeholder-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.login-content .regBtn:active {
    background: #363646 !important;
}

.react-datepicker-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    height: 44px;
    background: #363646;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.react-datepicker-wrapper input {
    background: transparent;
    padding: 12px;
    width: 100%;
    height: 44px;
    border: none;
    color: #fff;
}

.react-datepicker {
    font-size: 0.8rem;
    background-color: #363646;
    color: #fff;
    border: 1px solid #363646;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker__header {
    text-align: center;
    background-color: #363646;
    border-bottom: 1px solid #363646;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
    color: #fff;
}

/* .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
    background: #363646;

    
    border-radius: 4px;
    height: 42px;
    border:none !important;
    padding: 0px 8px;
} */
.reg-DOB {
    position: relative;
}

.dateicon {
    position: absolute;
    top: 32px;
    z-index: 1;
    right: 15px;
    pointer-events: none;
    color: #8f8fa3;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #363646;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #fff;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #fff;
    font-weight: bold;
    font-size: 0.944rem;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #1c1c26;
}

@media screen and (max-width:900px) {

    .login-content .reg-form {
        margin: 40px 0px 20px 0;
        padding: 60px 0 30px 0;
        width: 360px;
        height: 100%;
        z-index: 10;
    }

}


@media screen and (max-width:500px) {
    .login-content .reg-form {
        max-width: 360px;
        width: 100%;
        z-index: 10;
    }

}