.contactPage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 40px 10px;
}

.contactpage-left {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    justify-content: space-between;
    color: var(--white);
}

.contactpageDetails {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.contactpage-left span {
    color: var(--primary-color);
}

.contactpageDetails h4 {

    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0px;
    letter-spacing: -0.015em;
    /* color: #EEEEEE; */
    color: var(--white);
    max-width: 300px;
}

.contactpage-left p {
    color: var(--secondry-color);
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.015em;
    color: var(--white);
    max-width: 300px;

}

.contactpage-left svg,
.contactpage-left svg path {
    fill: none;
    max-width: 20px;
    width: 100%;
    max-height: 20px;
    height: 100%;
    stroke: var(--light-background);
}

.contactpage-left svg path {
    strokeWidth: 1px;
}


.contactpageDetails div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}


/* ***************** Contact Register ******************* */

.contactpage-right {
    background: var(--primary-bg);
    border-radius: 8px;
    padding: 30px;
}

.contactpage-right Form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;
}

.contactpage-right .name {
    margin-top: 0px;
}

.contactpage-right .form-label {
    /* color: var(--secondry-color); */
    color: var(--white);
    margin-bottom: 5px;
}

.contactpage-right .form-control {
    background: var(--input-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: var(--white);
    border: none;
    height: 44px;
}

.contactpage-right .name .form-control {
    width: calc(100% - 4px);
    height: 44px;
}

.contactpage-right form .submitBtn {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;

    height: 40px;
    background: var(--primary-color);
    border: none;
    border-radius: 30px;
    width: 100%;
}

.contactpage-right .form-control:focus {
    background-color: var(--input-color);
    color: var(--white);
    border: none;
}

.contactpage-right .form-control:focus {
    background-color: var(--input-color);
    color: var(--white);
    border: none;
    box-shadow: none;
}

.contactpage-right .contact-mail {
    position: relative;
}

.contactpage-right .contact-mail .form-control {
    padding-left: 35px;
}

.contactpage-right .contact-mail img {
    position: absolute;
    top: 44px;
    left: 13.33px;
}

.contactpage-right .contact-mail svg ,.contactpage-right .contact-mail svg path {
    position: absolute;
    top: 44px;
    left: 13.33px;
    stroke: var(--light-background);
}

/* .contactpage-right .contact-mail svg path {
    stroke: var(--light-background);
} */

.contactpage-right .contactpage-right-phone {
    color: var(--secondry-color);
}

.contactpage-right .react-tel-input .form-control {
    width: 100%;
    /* color:; */
}

.contactpage-right .react-tel-input .selected-flag {
    background: var(--input-color);
}

.contactpage-right .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background: var(--input-color);
}

.contactpage-right .react-tel-input .flag-dropdown {
    border: 1px solid var(--input-color);
    border-radius: 5px;
}

.contactpage-right .react-tel-input .flag-dropdown.open .selected-flag {
    background: var(--input-bg);
    border-radius: 3px 0 0 0;
}

.contactpage-right .react-tel-input .country-list {
    outline: none;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
    background-color: var(--input-bg);
    width: 200px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    z-index: 100;
}

.contactpage-right .react-tel-input .country-list::-webkit-scrollbar,
.contactpage-right .modalTextArea::-webkit-scrollbar {
    background-color: var(--primary-bg);
    width: 5px;
}

.contactpage-right .react-tel-input .country-list::-webkit-scrollbar-thumb,
.contactpage-right .modalTextArea::-webkit-scrollbar {
    background-color: var(--input-bg);
    width: 5px;
}

.contactpage-right .react-tel-input .country-list .country:hover {
    background-color: var(--primary-bg);
}

.contactpage-right .react-tel-input .country-list .country.highlight {
    background: var(--primary-bg);
}

.contactpage-right .css-1s2u09g-control {
    background-color: var(--input-bg);
    border: none;
    padding-left: 36px;
}


.contactpage-right.react-tel-input .in {
    background-position: -208px -55px;
    transform: scale(1.3);
}

.contactpage-right .modalTextArea {
    height: 80px;
}


.css-1ap7q7h-container {
    z-index: 0 !important;
}