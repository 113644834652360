@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700;800;900&family=Overpass:wght@100;200;300;400;500;600;700;800&family=Work+Sans:wght@100;200;300;400;500;600;700;800&display=swap";

:root {
  --main-bg: #13131b;
  --primary-bg: #1c1c26;
  --light-background: #eeeeee;
  --primary-color: #00cccb;
  --secondry-color: #eeeeee;
  --white: #ffffff;
  --largefont: 32px;
  --mediumfont: 26px;
  --paragraphfont: 18px;
  --linkfont: 16px;
  --smallfont: 14px;
  --fontcolor: #00cccb;
  --primary-color-hover: #06b7b7;
  --input-bg: #363646;
}

/* [data-theme="dark"] {
  --main-bg: #ffffff;
  --primary-bg: #f5f5f5;
  --white: #000000;
}

[data-theme="light"] {
  --main-bg: #000000;
  --primary-bg: #f5f5f5;
  --white: #ffffff;
} */
.dark {
  --inp-bg: "#13131b";
  --main-bg: #13131b;
  --primary-bg: #1c1c26;
  --light-background: #eeeeee;
  /* --light-background: #eeeeee; */
  --toggle-nav: #1c1c26;
  --primary-color: #00cccb;
  --secondry-color: #eeeeee;
  --white: #ffffff;
  --largefont: 32px;
  --mediumfont: 26px;
  --paragraphfont: 18px;
  --linkfont: 16px;
  --smallfont: 14px;
  --fontcolor: #00cccb;
  --primary-color-hover: #06b7b7;
  --input-bg: #363646;
  --toggle-color: #ffffff;
  --sidenav-color: #1c1c26;
  --icon-color: #13131b;
  --minitoggle-color: #2b2b37;
  --navtext-color: #eeeeee;
  --line-color: #E5E5E5;
  --placeholder-color: #ffffff;
  --input-color: #363646;
  --login-color: #13131b;
  --privacytext-color: #CBCBCB;
  --login-bg: #13131b;
  --svg-color: rgb(255, 255, 255);
  --togglenav-color: #ffffff;
}

.light {
  --main-bg: #ffffff;
  --inp-bg: "#efefef";
  /* --input-bg: "#edebeb"; */
  --primary-bg: #f5f5f5;
  /* --white: #070707; */
  --toggle-nav: #06b7b7;
  --togglenav-color: #06b7b7;
  --white: #475252;
  --secondry-color: #ffff;
  --light-background: #475252;
  --toggle-color: #ffffff;
  --sidenav-color: #f5f5f5;
  --icon-color: #475252;
  --minitoggle-color: #3e3e3e;
  --navtext-color: #6a6666;
  --line-color: black;
  --placeholder-color: #222121;
  /* --input-color: #efefef; */
  --input-color: #edebeb;
  --login-color: #ece7e7;
  --privacytext-color: #6a6666;
  --login-bg: #e1e1e1;
  --svg-color: #6a6666;
}


body {
  margin: 0;
  font-family: "Work Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg) !important;
  color: #fff !important;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}

input:-internal-autofill-selected {
  background: #363646 !important;
  border: none;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:hover {
  border: none;
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #363646 inset !important;
  border: none;
  -webkit-text-fill-color: var(--white) !important;
  caret-color: white;
}

.css-b5zk0q-control,
.css-13yc3fr-control,
.css-319lph-ValueContainer {
  height: 44px;
  min-height: 44px !important;
}

.css-jl6rg4-singleValue {
  width: 100%;
}

.react-tel-input .flag-dropdown.open,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
}

.css-1oqu4dy-menu {
  background: none !important;
}

.css-1f43avz-a11yText-A11yText {
  z-index: 0 !important;
}