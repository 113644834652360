.modalContactUs-wrapper {
    position: relative;
    border-radius: 8px;
}

.modalContactUs-wrapper .modal-content {
    max-width: 420px;
    width: 100%;
    background-color: transparent;
}

.modalContactUs-wrapper .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;
    background: var(--primary-bg);
    width: 100%;
    border-radius: 8px;
}

.modalContactUs-wrapper .modalClose-btn {
    position: absolute;
    top: -15px;
    right: -15px;
    background: var(--primary-color);
    border-radius: 30px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.modalContactUs h4 {
    color: var(--secondry-color);
}

.modalContactUs Form {
    display: flex;
    flex-direction: column;
    gap: var(--linkfont);
    /* max-width: 360px; */
    width: 100%;
}

.modalContactUs .form-label {
    color: var(--navtext-color);
}

.modalContactUs .form-control {
    background: var(--input-bg);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: var(--white);
    border: none;
    height: 44px;
}

.modalContactUs .name .form-control {
    width: calc(100% - 4px);
    height: 44px;
}

.modalContactUs form .submitBtn {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    height: 40px;
    background: var(--primary-color);
    border: none;
    border-radius: 30px;
    width: 100%;
}

.modalContactUs .form-control:focus {
    background-color: var(--input-bg);
    color: var(--white);
    border: none;
}

.modalContactUs .form-control:focus {
    background-color: var(--input-bg);
    color: var(--white);
    border: none;
    box-shadow: none;
}

.modalContactUs .modal-mail {
    position: relative;
}

.modalContactUs .modal-mail .form-control {
    padding-left: 36px;
}

.modalContactUs .modal-mail img {
    position: absolute;
    top: 45px;
    left: 13.33px;
}

.modalContactUs .modalContactUs-phone {
    color: var(--secondry-color);
}

.modalContactUs .react-tel-input .form-control {
    width: 100%;
}

.modalContactUs .react-tel-input .selected-flag {
    background: var(--input-bg);

}

.modalContactUs .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background: var(--input-bg);
}

.modalContactUs .react-tel-input .flag-dropdown {
    border: 1px solid var(--input-bg);
    border-radius: 5px;
}

.modalContactUs .react-tel-input .flag-dropdown.open .selected-flag {
    background: var(--input-bg);
    border-radius: 3px 0 0 0;
}

.modalContactUs .react-tel-input .country-list {
    outline: none;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
    background-color: var(--input-bg);
    width: 200px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    z-index: 100;
}

.modalContactUs .react-tel-input .country-list::-webkit-scrollbar,
.modalContactUs .modalTextArea::-webkit-scrollbar {
    background-color: var(--primary-bg);
    width: 5px;
}

.modalContactUs .react-tel-input .country-list::-webkit-scrollbar-thumb,
.modalContactUs .modalTextArea::-webkit-scrollbar {
    background-color: var(--input-bg);
    width: 5px;
}

.modalContactUs .react-tel-input .country-list .country:hover {
    background-color: var(--primary-bg);
}

.modalContactUs .react-tel-input .country-list .country.highlight {
    background: var(--primary-bg);
}

.modalContactUs .css-1s2u09g-control {
    background-color: var(--input-bg);
    border: none;
    padding-left: 36px;
}

.modalContactUs .modal-select {
    position: relative;
}

.modalContactUs .modal-select img {
    position: absolute;
    left: 12px;
    top: 44px;
    z-index: 1;
}

.modalContactUs.react-tel-input .in {
    background-position: -208px -55px;
    transform: scale(1.3);
}

.modalContactUs .modalTextArea {
    height: 90px;
}