.privacy-page {
    margin-top: 20px;
    padding: 20px 25px;
    background-color: var(--primary-bg);
    /* max-width: 1050px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 6px;
}

.privacy-page p {
    color: var(--privacytext-color);
    font-size: var(--smallfont);
}

.privacy-page span {
    color: var(--primary-color);
}

.privacy-page h4 {
    font-size: var(--paragraphfont);
    font-weight: 700;
    color: var(--white);
}

@media screen and (max-width: 1230px) {
    .privacy-page {
        margin-top: 20px;
    }
}

@media screen and (max-width: 620px) {
    .privacy-page {
        padding: 10px 20px;
        gap: 10px;
    }

}