.contactus-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
}

.contactus-content-right img {
  max-width: 561px;
  width: 100%;
}

.contactus-content-container {
  padding: 40px 0;
}

.contactus-content-left {
  max-width: 435px;
  width: 100%;
}

.contactus-content-left h2 {
  font-weight: 700;
  font-size: var(--largefont);
  line-height: 140%;
  color: var(--white);
  margin: 0;
  padding-bottom: 15px;
}

.contactus-content-left h2 span {
  color: var(--primary-color);
}

.contactus-content-left p {
  font-weight: 400;
  font-size: var(--paragraphfont);
  line-height: 30px;
  letter-spacing: -0.015em;
  color: var(--light-background);
  margin: 0;
  max-width: 356px;
  width: 100%;
  padding-bottom: 25px;
}