.requestNotFound {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.requestNotFound img {
    max-width: 591.84px;
    width: 100%;
    max-height: 420.77px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorMssg {
    font-size: 17px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #EEEEEE;
}


.errorBtn button:hover,
.errorBtn button:active,
.errorBtn button:focus,
.errorBtn button {
    width: 190px;
    height: 40px;
    left: 588px;
    top: 654px;
    background-color: var(--fontcolor) !important;
    border: 1px solid var(--fontcolor) !important;
    border-color: var(--fontcolor) !important;
    box-shadow: -15px 15px 20px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
}

.errorBtn a {
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
}