.login-page {
    position: relative;
    display: flex;
    width: 100%;
}

.login-page .logo {
    position: absolute;
    top: 50px;
    left: 50px;
    max-width: 100%;
    z-index: 10;
}

.login-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    width: 50%;
    height: 100vh;
    background: var(--primary-bg);
}

.login-content .login-form {
    width: 360px;
}

.login-form h4 {
    font-weight: 600;
    font-size: 20px;
    color: var(--white);

}

.login-form p {
    padding-bottom: 8px;
    font-weight: 400;
    font-size: var(--smallfont);
    line-height: var(--linkfont);
    color: var(--white);
}

.login-name {
    position: relative;
    margin-top: var(--linkfont);
}

.login-form span {
    color: #D24E4E;
    display: inline;
}

.login-form .form-label {
    font-weight: 400;
    font-size: 12px;
    line-height: var(--smallfont);
    color: var(--white);
}

.login-name svg {
    position: absolute;
    top: 42px;
    left: var(--smallfont);
}

.login-name svg path {
    stroke: var(--light-background);
}

.login-form .form-control {
    background: var(--input-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    color: var(--placeholder-color);
    padding: 12px 12px 12px 35px;
    height: 44px;
    font-size: var(--linkfont);
}

.login-form input::placeholder {
    color: var(--secondary-color);
    font-weight: 400;
    font-size: var(--linkfont);
    line-height: 19px;
    opacity: 0.5;

}

.toggle-Link {
    margin-top: 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.login-content a {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--primary-color);
    text-decoration: none;
}

.form-switch {
    display: flex;
    gap: 8px;
    align-items: center;
}

.form-switch .form-check-input {
    width: 40px;
    height: 20px;
    cursor: pointer;
}

input#custom-switch {
    box-shadow: none;
}

.form-switch .form-check-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--white);
    cursor: pointer;
}

.login-line {
    margin-top: var(--largefont);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--line-color);
    font-size: 12px;
}

.login-line hr {
    color: var(--line-color);
    width: 100%;
    height: 0.5px;
}

.notHav-acc {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: var(--white);
}

@media screen and (max-width:900px) {
    .login-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: var(--primary-bg);
        padding: 24px;
    }

    .login-content .login-form {
        margin: 40px 0px 20px 0;
        width: 360px;
        z-index: 10;
    }

    .login-page .logo {
        position: absolute;
        top: 20px;
        left: 30px;
        max-width: 100%;
        z-index: 10;
    }

}

@media screen and (max-width:768px) {
    .login-content .login-form {
        margin-top: 180px;
    }
}

@media screen and (max-width:500px) {
    .login-content .login-form {
        max-width: 360px;
        width: 100%;
        z-index: 10;
        /* margin-top: 60px; */
        margin-top: 180px;
    }
}