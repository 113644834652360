/****************** Slider ********************/
.home-slider-content .slick-center .home-slider-box {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.home-slider-content {
  max-width: 1520px;
  width: 100%;
  margin: 0px auto;
}

.home-slider {
  padding: 40px 0;
}

.home-slider-box img {
  width: 100%;
  border-radius: 8px;
}

.home-slider-content .slick-next:before {
  content: "";
  background: url(../asset/image/home/rightarrow.svg);
  width: 34px;
  height: 34px;
  display: block;
  opacity: 1;
  background-size: contain;
  background-repeat: no-repeat;
}

.home-slider-content .slick-prev:before {
  content: "";
  background: url(../asset/image/home/leftarrow.svg);
  width: 34px;
  height: 34px;
  opacity: 1;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.home-slider-content .slick-prev,
.home-slider-content .slick-next {
  width: 34px;
  height: 34px;
  z-index: 9;
}

.home-slider-content .slick-dots li button:before {
  opacity: 1;
  content: "";
  background: #343a4099;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transition: all 0.5s;
  color: transparent;
  top: 50%;
  left: 50%;
  font-size: 0px;
  font-family: inherit;
  transform: translate(-50%, -50%);
}

.home-slider-content .slick-dots li.slick-active button:before {
  background: #343a40;
  width: var(--paragraphfont);
  border-radius: 55px;
  color: transparent;
}

.home-slider-content .slick-dots li button {
  border: none;
  padding: 0;
  position: absolute;
}

.home-slider-content .slick-dots {
  bottom: 10px;
}

.home-slider-content .slick-dots li button:hover,
.home-slider-content .slick-dots li button:focus {
  outline: none;
  border: none;
}

.home-slider-content .slick-prev {
  left: 0px;
}

.home-slider-content .slick-next {
  right: 0px;
}

/* ********************** Game Grid ********************* */
.home-grid-content-container {
  padding: 40px 0;
}

.home-grid-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.home-grid-head h2 {
  font-weight: 700;
  font-size: var(--mediumfont);
  line-height: 140%;
  color: var(--fontcolor);
  margin: 0;
}

.home-grid-head h2 span {
  color: var(--white);
}

.home-grid-content {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(
    auto-fill, minmax(200px, 1fr));
  /* grid-template-columns: repeat(4, minmax(0, 1fr)); */
}

.home-grid-game {
  /* max-width: 302px; */
  min-width: 180px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  position: relative;
  transition: all 0.5s;
  overflow: hidden;
  cursor: pointer;
}

.home-grid-game:hover:before {
  content: "";
  position: absolute;
  background: url(../asset/image/gamegrid/play.svg) no-repeat center rgba(0, 204, 203, 0.5);
  /* max-width: 302px; */
  min-width: 180px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  z-index: 9;
}

.home-grid-game img {
  /* max-width: 302px; */
  width: 100%;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}



.home-grid-game:hover img {
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}


.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f500;
  border: 1px solid #cacaca00;
  border-radius: 3px 0 0 3px;
}

.sidebar-expand .container {
  max-width: calc(100% - 80px);
  margin-left: 80px;
}

/*************************** Media Query ****************************/

@media screen and (max-width:1024px) {
  .skill-search input {
    margin-left:50px;
  }
 .skill-search:before {
    right: 30px;
 }
  .accordion h2 {
    font-size: 16px;
  }

  .skill-faq-content-container .skill-faq-head h2 {
    padding-left: 52px;
    text-align: start;
    font-weight: 500;
    font-size: var(--paragraphfont);
    line-height: 21px;
    letter-spacing: -0.015em;
    color: var(--white);
    margin: 0;
    padding-bottom: 5px;
  }

  .skill-faq-body p {
    font-weight: 400;
    max-width: 1000px;
    width: 100%;

    font-size: var(--smallfont);
    line-height: 140%;
    letter-spacing: -0.015em;
    color: var(--light-background);
    opacity: 0.7;
    text-align: start;
  }

  .skill-faq-head span {
    font-weight: 700;
    font-size: var(--largefont);
    line-height: 21px;
    letter-spacing: -0.015em;
    color: var(--fontcolor);
  }

  .skill-faq-content .accordion-button::after {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

@media screen and (max-width:991px) {

  .skill-faq-content-container .skill-faq-head h2 {
    padding-left: 34px;
  }

  .skill-faq-head {
    padding-right: 40px;
  }

  .skill-faq-content .accordion-body {
    padding: 20px 20px 20px 60px;
  }
}

@media screen and (max-width:767px) {
  .searchDropdown {
    top: 96px;
    left: 0px;
  }

  .contactPage {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .contactpageDetails h4 {

    font-size: 16px;

  }
  .header-content-container {
    display: none;
  }

  .headerNavbar-mobileView {
    position: relative;
    display: flex;
  }

  .mini-sidebar-btn img {
    transform: rotate(0deg);
    transition: all 0.2s;
  }

  .sidebar-expand .mini-sidebar-btn img {
    transform: rotate(180deg);
  }

  .mini-sidebar-content {
    display: none;
  }

  .sidebar-expand .mini-sidebar-btn {
    right: 50px;
    position: fixed;
  }

  .mobileView-icons {
    margin-right: 45px;
  }

  h1 {
    font-size: 26px !important;
  }

  h2 {
    font-size: 25px !important;
  }

  h3 {
    font-size: 16px !important;
  }

  p {
    font-size: 12px !important;
    line-height: 140% !important;
  }

  button {
    font-size: 12px !important;
  }

  .privacyPara {
    font-size: 12px !important;
  }

  .sidebar-menu-list a,
  .sidebar-menu-list button {
    font-size: 14px !important;
  }

  .content-wrapper {
    width: calc(100% - 0px);
    margin-left: 0px;
  }

  .home-grid-head {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .skill-faq-content-container .skill-faq-head h2 {
    padding-left: 22px;
  }
  .skill-faq-content {
    padding: 0px 20px !important;
  }

  .skill-faq-content .accordion-body {
    padding: 15px 20px 15px 40px;
  }

  .skill-faq-head {
    padding-right: 20px;
  }

  .contactus-content {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .freecoin-content {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    row-gap: 20px;
  }


  .work-content {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-top: 10px;
  }

  .skill-dark-button {
    display: none;
  }

  .skill-transparent-button {
    display: none;
  }

  .skill-header-btn {
    gap: 8px;
  }

  .sidebar-expand .container {
    margin-left: 0px;
    margin: auto;
  }

  .skill-massage {
    padding: 5px;
    cursor: pointer;
  }

  .footer-detail-right ul {
    gap: 15px;
  }
}


@media screen and (max-width:375px) {
  .sidebar-expand .container {
    max-width: 100%;
  }
  .sidebar-expand .mini-sidebar-btn {
    right: 15px;
  }

}

@media screen and (max-width:375px) {
  .mobileView-icons {
    margin-right: 35px;
}
}